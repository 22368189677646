import React from "react";

import style from "./Checkbox.module.scss";

interface CheckboxProps {
  onToggle: () => void;
  isChecked: boolean;
  children?: React.ReactNode;
}

const Checkbox: React.FC<CheckboxProps> = (props: CheckboxProps) => {
  return (
    <div className={style.checkboxWrapper} onClick={props.onToggle}>
      <span className={style.checkbox}>
        <input type="checkbox" checked={props.isChecked} readOnly />
        <span></span>
      </span>
      <label className={style.checkboxLabel}>{props.children}</label>
    </div>
  );
};

export default Checkbox;
