// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._12qfBjRol8dcGDzNkBSdBm{display:flex;height:100%;flex-direction:column;padding-top:1.42857rem}._3ehzv7OAfqAeV3KgppYU9H{text-align:center}._36zptTqQ5ik3IsZvxSeyMm{width:70%;margin:0 auto;line-height:4.28571rem;padding-top:4.28571rem;font-weight:600;font-size:3.14286rem;color:#25436d}._1xCndqsb8u0WqHRPRhlK61{height:100%;display:flex;flex-direction:column}._31x4BPbYYYYdAqgHqKTSCL{justify-content:center;flex:1;align-items:center;display:flex}._1IZF17AgLgewEXXYhvAmR9{display:flex;flex:2;margin:0 auto;align-items:center}._1IZF17AgLgewEXXYhvAmR9._1clOLakFpnzGbQ1pGL6E5g{width:80%}._1Ao4RMhjm6EPWoBuu51gxj{padding:1rem 0}._1clOLakFpnzGbQ1pGL6E5g .L3XG7h_2UgRuf0Vz5zycm{column-count:2;width:100%}._1_0Tex5Y5ab9EH2bwlBR9h{text-align:center}\n", ""]);
// Exports
exports.locals = {
	"userDeficitTop": "_12qfBjRol8dcGDzNkBSdBm",
	"userDeficitTopText": "_3ehzv7OAfqAeV3KgppYU9H",
	"userDeficitHeader": "_36zptTqQ5ik3IsZvxSeyMm",
	"userDeficitContainer": "_1xCndqsb8u0WqHRPRhlK61",
	"userDeficitBtnWrapper": "_31x4BPbYYYYdAqgHqKTSCL",
	"userDeficitOptions": "_1IZF17AgLgewEXXYhvAmR9",
	"columns": "_1clOLakFpnzGbQ1pGL6E5g",
	"userDataItem": "_1Ao4RMhjm6EPWoBuu51gxj",
	"userOptionsWrapper": "L3XG7h_2UgRuf0Vz5zycm",
	"errorWrapper": "_1_0Tex5Y5ab9EH2bwlBR9h"
};
module.exports = exports;
