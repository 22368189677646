import { action, computed, observable } from "mobx";
import { User } from "../interfaces/User";
import { Licence } from "../interfaces/Licence";
import { Session } from "../interfaces/Session";
import UserApiService from "../services/UserApiService";
import AnalyticsApiService from "../services/AnalyticsApiService";
import PaginationStore from "./PaginationStore";
import { ApiHttpResponseWithPagination } from "../interfaces/AbstractHttpService";
import LicenceApiService from "../services/LicenceApiService";
import SessionApiService from "../services/SessionApiService";
import { ANALYTICS } from "../components/constants/analytics";

interface UsersWithSession extends User {
  lastSession: string;
}

export default class UsersStore {
  private PER_PAGE = 3;

  constructor(
    public UserApi: UserApiService,
    public LicenceApi: LicenceApiService,
    public sessionApi: SessionApiService,
    public analyticsApi: AnalyticsApiService
  ) {
    this.users = new PaginationStore<User>(this.nextPage, {
      perPage: 21,
    });

    this.init();
  }

  @observable users: PaginationStore<User>;
  @observable computedUsersWithSession: UsersWithSession[] = [];
  @observable licence: Licence | null = null;
  @observable isInitialized = false;
  @observable isError = false;
  @observable isFinished = false;

  @observable start = 0;

  @action async init(): Promise<void> {
    this.users.getNextPage();
    this.getLicence();
    this.analyticsApi.sendAnalytics({
      type: ANALYTICS.SPECIALIST,
      action: ANALYTICS.PROFILE_LIST,
    });
  }

  @action async getLicence(): Promise<void> {
    try {
      this.licence = await this.LicenceApi.getLicence();
    } catch (e) {
      this.isError = true;
    } finally {
      this.isFinished = true;
    }
  }

  @action async actualUserData(): Promise<void> {
    try {
      const actions = this.actualData.map(async (item) => {
        return this.getUserSession(item.id)
          .then((sessionData) => {
            return {
              ...item,
              lastSession: sessionData.body[0].createdAt,
            };
          })
          .catch(() => {
            return {
              ...item,
              lastSession: "",
            };
          });
      });

      this.computedUsersWithSession = await Promise.all(actions);
    } catch (e) {
      this.isError = true;
    } finally {
      this.isFinished = true;
    }
  }

  @action async getUserSession(userId: string): Promise<ApiHttpResponseWithPagination<Session[]>> {
    return await this.sessionApi.getUserSessions(userId, { page: 1, perPage: 1 });
  }

  @computed get actualData(): User[] {
    return this.users.data.slice(this.start, this.start + this.PER_PAGE);
  }

  @computed get isPrevPage(): boolean {
    return this.start > 0;
  }

  @computed get isNextDataLoaded(): boolean {
    return this.start < this.users.data.length - this.PER_PAGE;
  }

  @computed get isNextPage(): boolean {
    return this.isNextDataLoaded || !this.users.isFinish;
  }

  @action prev(): void {
    this.start -= this.PER_PAGE;
  }

  @action next(): void {
    if (this.isNextDataLoaded) {
      this.start += this.PER_PAGE;
    } else {
      this.start += this.PER_PAGE;
      this.users.getNextPage();
    }
  }

  nextPage = (page: number, perPage: number): Promise<ApiHttpResponseWithPagination<User[]>> => {
    return this.UserApi.getUsers({ perPage, page });
  };
}
