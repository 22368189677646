import React, { useState, useContext } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import logo from "../../../assets/images/aba-stroke.svg";
import BaseLayoutAnimated from "../../../../ui/layout/BaseLayoutAnimated";
import LanguageSwitch from "../../languageSwitch/LanguageSwitch";
import Input from "../../../../ui/Input/Input";
import AnimatedButton from "../../../../ui/AnimatedButton/AnimatedButton";
import Label from "../../../../ui/Label/Label";
import Checkbox from "../../../../ui/Checkbox/Checkbox";
import AuthStoreContext from "../../../context/AuthStoreContext";
import AspectRatio from "../../../../ui/layout/AspectRatioContainer";
import Loader from "../../loader/Loader";
import { paths } from "../../constants/paths";

import styles from "./LoginPage.module.scss";

const LoginPage: React.FC = () => {
  const [error, setError] = useState("");
  const [key, setKey] = useState("");
  const [checked, setChecked] = useState(false);
  const [logging, setLogging] = useState(false);
  const { formatMessage: f } = useIntl();
  const auth = useContext(AuthStoreContext);
  const history = useHistory();

  const renderTop = (): React.ReactNode => {
    return (
      <div className={styles.loginPageTop}>
        <LanguageSwitch />
        <div className={styles.loginPageLogo}>
          <img src={logo} alt="" />
        </div>
      </div>
    );
  };

  const onFormChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setKey(value);
  };

  const handleBtnClick = (): void => {
    setError("");

    if (!checked) {
      return setError("page.login.label.error2");
    }

    setLogging(true);

    auth
      .login(key)
      .then(({ redirectUrl }) => {
        history.push(redirectUrl ? redirectUrl : paths.default);
      })
      .catch(() => {
        setError("page.login.label.error");
        setLogging(false);
      });
  };

  const toggleCheckbox = (): void => {
    setChecked((checked) => !checked);
  };

  if (logging) {
    return (
      <AspectRatio>
        <Loader />
      </AspectRatio>
    );
  }

  return (
    <BaseLayoutAnimated top={renderTop()}>
      <div className={styles.loginPageContent}>
        <div className={styles.loginPageTitle}>
          <span>{f({ id: "page.login.title" })}</span>
        </div>
        <div className={styles.loginPageForm}>
          <Input onChange={onFormChange} error={!!error} errorLabel={error ? f({ id: error }) : null} />
          <div className={styles.loginPageTerms}>
            <Checkbox isChecked={checked} onToggle={toggleCheckbox}>
              <span className={styles.loginPageCheckboxText}>{f({ id: "page.login.terms" })}</span>
              <a
                href="https://production-aba-games-data.s3.eu-central-1.amazonaws.com/regulamin.pdf"
                className={styles.loginPageTermsLink}
              >
                <Label type="danger"> {f({ id: "page.login.regulations" })}</Label>
              </a>
            </Checkbox>
          </div>
          <AnimatedButton onClick={handleBtnClick}>{f({ id: "page.login.btn" })}</AnimatedButton>
        </div>
      </div>
    </BaseLayoutAnimated>
  );
};

export default LoginPage;
