export const DeficitConfig = [
  {
    title: "page.user.deficit.title.1",
    data: [
      {
        name: "page.user.deficit.label.1",
        slug: "attention",
      },
      {
        name: "page.user.deficit.label.2",
        slug: "memory",
      },
      {
        name: "page.user.deficit.label.3",
        slug: "abstract-thinking",
      },
      {
        name: "page.user.deficit.label.4",
        slug: "logical-thinking",
      },
      {
        name: "page.user.deficit.label.5",
        slug: "visual-gnosis",
      },
      {
        name: "page.user.deficit.label.6",
        slug: "spatial-orientation",
      },
      {
        name: "page.user.deficit.label.7",
        slug: "naming",
      },
      {
        name: "page.user.deficit.label.8",
        slug: "reading",
      },
      {
        name: "page.user.deficit.label.9",
        slug: "speech-understanding",
      },
      {
        name: "page.user.deficit.label.10",
        slug: "praxis",
      },
    ],
  },
  {
    title: "page.user.deficit.title.2",
    data: [
      {
        name: "page.user.deficit.label.11",
        slug: "side-skipping",
      },
      {
        name: "page.user.deficit.label.12",
        slug: "arm-paresis",
      },
      {
        name: "page.user.deficit.label.13",
        slug: "psychomotor-retardation",
      },
    ],
  },
];
