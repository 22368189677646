import AbstractHttpService from "./AbstractHttpService";
import { Award, UserAward, BoostPoints } from "../interfaces/Award";

class AwardsApiService extends AbstractHttpService {
  getAwards(): Promise<Award[]> {
    return this.http
      .get("api/specialist/v1/category_awards")
      .then((response) => this.resolve<Award[]>(response))
      .catch(this.reject);
  }

  getUserAwards(id: string): Promise<UserAward[]> {
    return this.http
      .get(`api/specialist/v1/patients/${id}/awards`)
      .then((response) => this.resolve<UserAward[]>(response))
      .catch(this.reject);
  }

  drawUserAwards(userId: string, awardId: string): Promise<UserAward> {
    return this.http
      .post(`api/specialist/v1/patients/${userId}/award_categories/${awardId}/awards/draw`)
      .then((response) => this.resolve<UserAward>(response))
      .catch(this.reject);
  }

  getBoostPoints(id: string): Promise<BoostPoints> {
    return this.http
      .get(`api/specialist/v1/patients/${id}/boost_points`)
      .then((response) => this.resolve<BoostPoints>(response))
      .catch(this.reject);
  }

  addBoostPoints(id: string, amount: number): Promise<BoostPoints> {
    return this.http
      .put(`api/specialist/v1/patients/${id}/boost_points`, { body: JSON.stringify({ amount: amount }) })
      .then((response) => this.resolve<BoostPoints>(response))
      .catch(this.reject);
  }
}

export default AwardsApiService;
