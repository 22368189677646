import React, { useEffect, useMemo } from "react";
import classnames from "classnames";
import { useIntl } from "react-intl";
import { useParams, useHistory } from "react-router-dom";
import { observer } from "mobx-react";

import AlbumsLayout from "./AlbumsLayout";
import Loader from "../../loader/Loader";

import blocked from "../../../assets/images/blocked.svg";
import arrRight from "../../../assets/images/arr-right.svg";
import arrLeft from "../../../assets/images/arr-left.svg";
import stores from "../../../stores";
import { createPath } from "../../constants/paths";

import styles from "./AlbumsCategoryPage.module.scss";

const AlbumsCategoryPage: React.FC = observer(() => {
  const { formatMessage: f } = useIntl();
  const { categoryId, userId } = useParams();
  const history = useHistory();
  const store = useMemo(() => stores.createAlbumStore(), []);
  const language = stores.language.language;

  useEffect(() => {
    store.getCategoryItems(userId, categoryId);
  }, [store, userId, categoryId]);

  const renderItems = (): React.ReactNode => {
    if (store.actualData.length === 0) {
      return <Loader />;
    }

    return store.actualData.map((item, index) => {
      const fileIndex = store.categoryItems.indexOf(item) + 1;
      if (item.data) {
        return (
          <div
            key={index}
            className={styles.albumItem}
            onClick={() => {
              history.push(createPath.albumsItem(userId, categoryId, fileIndex));
            }}
          >
            <div className={styles.albumItemImgWrapper}>
              <span className={styles.albumItemNumber}>{fileIndex}</span>
              <div className={styles.albumItemImg}>
                <img src={item.data.imageUrl} />
              </div>
            </div>
            <div className={styles.albumItemName}>{item.data.translations[language].name}</div>
          </div>
        );
      }

      return (
        <div key={index} className={classnames(styles.albumItem, styles.blocked)}>
          <div className={styles.albumItemImgWrapper}>
            <span className={styles.albumItemNumber}>{fileIndex}</span>
            <div className={styles.albumItemImg}>
              <img src={blocked} />
            </div>
          </div>
          <div className={styles.albumItemName}>{f({ id: "album.blocked" })}</div>
        </div>
      );
    });
  };

  return (
    <AlbumsLayout
      onBack={() => history.push(createPath.albums(userId))}
      imageSrc={store.currentCategory?.thumbnailUrl}
      categoryName={store.currentCategory?.translations[language].name}
    >
      <div
        className={classnames(styles.albumArrow, {
          [styles.disabled]: !store.isPrevPage,
        })}
      >
        <img src={arrLeft} onClick={() => store.prev()} />
      </div>
      <div className={styles.albumItemWrapper}>
        <div className={styles.albumItemsContainer}>{renderItems()}</div>
      </div>
      <div
        className={classnames(styles.albumArrow, {
          [styles.disabled]: !store.isNextPage,
        })}
      >
        <img src={arrRight} onClick={() => store.next()} />
      </div>
    </AlbumsLayout>
  );
});

export default AlbumsCategoryPage;
