// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3K9KuSpmN8-KZfO_t73eYS{width:100%;height:100%;top:0;left:0;position:absolute;overflow:hidden}._1UOyCV7HUHnw106_PfKjE-{width:100%;height:100%;top:-100%;left:0;position:absolute;background:#7dd582;transition:top 1s;z-index:10}._1q5lDWKxYfS3oNkw3TOaQh{top:100%}\n", ""]);
// Exports
exports.locals = {
	"curtainContainer": "_3K9KuSpmN8-KZfO_t73eYS",
	"curtain": "_1UOyCV7HUHnw106_PfKjE-",
	"animation": "_1q5lDWKxYfS3oNkw3TOaQh"
};
module.exports = exports;
