import AbstractHttpService from "./AbstractHttpService";
import { User, CreateUserData } from "../interfaces/User";
import { ApiHttpError, ApiHttpResponseWithPagination } from "../interfaces/AbstractHttpService";
import { PaginationConfig } from "../interfaces/AbstractHttpService";

class UserApiService extends AbstractHttpService {
  addUser(form: CreateUserData): Promise<unknown> {
    return this.http
      .post(`api/specialist/v1/patients`, { body: JSON.stringify(form) })
      .then(this.resolve)
      .catch(this.reject);
  }

  updateUser(id: string, form: CreateUserData): Promise<unknown> {
    return this.http
      .patch(`api/specialist/v1/patients/${id}`, { body: JSON.stringify(form) })
      .then(this.resolve)
      .catch(this.reject)
      .catch((err: ApiHttpError) => {
        return Promise.reject(err);
      });
  }

  getUser(id: string): Promise<User> {
    return this.http
      .get(`api/specialist/v1/patients/${id}`)
      .then((response) => this.resolve<User>(response))
      .catch(this.reject);
  }

  deleteUser(id: string): Promise<unknown> {
    return this.http.delete(`api/specialist/v1/patients/${id}`).then(this.resolve).catch(this.reject);
  }

  getUsers(config: PaginationConfig): Promise<ApiHttpResponseWithPagination<User[]>> {
    const query = this.createPaginationQuery(config);

    return this.http
      .get(`api/specialist/v1/patients?${query}`)
      .then((response) => this.resolveWithPaginationHeaders<User[]>(response))
      .catch(this.reject);
  }
}

export default UserApiService;
