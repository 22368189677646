import { action, observable } from "mobx";
import { Toasts } from "../interfaces/Toasts";

export default class ToastsStore {
  @observable data: Toasts[] = [];

  @action deleteToast(id: number): void {
    this.data = this.data.filter((item) => item.id !== id);
  }

  @action add(data: Toasts): void {
    const timeout = data.timeout || 5000;
    const id = new Date().getTime();

    this.data.push({
      timeout,
      id,
      ...data,
    });

    setTimeout(() => {
      this.data = this.data.filter((item) => item.id !== id);
    }, timeout);
  }
}
