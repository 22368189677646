import React from "react";
import { observer } from "mobx-react";
import { useIntl } from "react-intl";
import classnames from "classnames";
import Checkbox from "../../../../ui/Checkbox/Checkbox";
import AnimatedButton from "../../../../ui/AnimatedButton/AnimatedButton";
import BaseLayoutAnimated from "../../../../ui/layout/BaseLayoutAnimated";
import Loader from "../../loader/Loader";

import styles from "./UserDeficitPage.module.scss";

interface DataInterface {
  name: string;
  slug: string;
}

interface UserDeficitProps {
  title: string;
  data: DataInterface[];
  selectedData: string[];
  loading?: boolean;
  onCognitiveChange: (slug: string) => void;
  onBtnNext: () => void;
  onBtnPrev: () => void;
}

const UserDeficitPage: React.FC<UserDeficitProps> = observer((props: UserDeficitProps) => {
  const { formatMessage: f } = useIntl();

  const renderTop = (): React.ReactNode => {
    return (
      <div className={styles.userDeficitTop}>
        <div>
          <AnimatedButton variant="secondary" onClick={props.onBtnPrev}>
            {f({ id: "btn.back" })}
          </AnimatedButton>
        </div>
        <div className={styles.userDeficitTopText}>
          <h2 className={styles.userDeficitHeader}>{f({ id: props.title })}</h2>
        </div>
      </div>
    );
  };

  const isChecked = (slug: string): boolean => {
    const isSelected = props.selectedData.find((item) => item === slug);

    return !!isSelected;
  };

  const renderContent = (): React.ReactNode => {
    return props.data.map((item, id) => {
      return (
        <div className={styles.userDataItem} key={id}>
          <Checkbox onToggle={() => props.onCognitiveChange(item.slug)} isChecked={isChecked(item.slug)}>
            {f({ id: item.name })}
          </Checkbox>
        </div>
      );
    });
  };

  const doubleColumns = props.data.length > 8;

  return (
    <BaseLayoutAnimated top={renderTop()}>
      {props.loading ? (
        <Loader />
      ) : (
        <div className={styles.userDeficitContainer}>
          <div
            className={classnames(styles.userDeficitOptions, {
              [styles.columns]: doubleColumns,
            })}
          >
            <div className={styles.userOptionsWrapper}>{renderContent()}</div>
          </div>
          <div className={styles.userDeficitBtnWrapper}>
            <AnimatedButton onClick={props.onBtnNext}>{f({ id: "btn.next" })}</AnimatedButton>
          </div>
        </div>
      )}
    </BaseLayoutAnimated>
  );
});

export default UserDeficitPage;
