import React, { useContext } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { observer } from "mobx-react";

import { RouteInterface, SCOPES } from "./constants/routes";
import AuthStoreContext from "../context/AuthStoreContext";
import { paths } from "./constants/paths";

const RouteAuth: React.FC<RouteInterface> = observer(({ scope, component, ...props }: RouteInterface) => {
  const auth = useContext(AuthStoreContext);
  const { isLogged } = auth;

  function renderUnauthorized(ownProps: RouteProps): React.ReactNode | Redirect {
    const Component = component as React.ComponentType;

    return <Component {...ownProps} />;
  }

  function renderAuthorized(ownProps: RouteProps): React.ReactNode | Redirect {
    const Component = component as React.ComponentType;

    if (isLogged) {
      return <Component {...ownProps} />;
    } else {
      return <Redirect to={paths.login} />;
    }
  }

  if (scope === SCOPES.UNAUTHORIZED) {
    return <Route {...props} render={renderUnauthorized} />;
  }

  return <Route {...props} render={renderAuthorized} />;
});

export default RouteAuth;
