// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3jaymG3Q04jqGXo_ytl17E{display:flex;height:100%;align-items:center;flex-direction:column;justify-content:center}.Q0tTZi51VXDpnIpeCBV5L{font-weight:600;font-size:3.14286rem;line-height:4.28571rem;color:#25436d;text-align:center;width:40%;margin:0 auto}.qoyLfvPPBWoeQPGd78jc3{display:flex;justify-content:space-between;width:60%;padding-top:7.57143rem}\n", ""]);
// Exports
exports.locals = {
	"modalBody": "_3jaymG3Q04jqGXo_ytl17E",
	"modalTitle": "Q0tTZi51VXDpnIpeCBV5L",
	"btnWrapper": "qoyLfvPPBWoeQPGd78jc3"
};
module.exports = exports;
