import AbstractHttpService from "./AbstractHttpService";
import { Licence } from "../interfaces/Licence";

class LicenceApiService extends AbstractHttpService {
  getLicence(): Promise<Licence> {
    return this.http
      .get("api/specialist/v1/users/licence_status")
      .then((response) => this.resolve<Licence>(response))
      .catch(this.reject);
  }
}

export default LicenceApiService;
