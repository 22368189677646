import React, { useEffect, useState } from "react";
import classnames from "classnames";

import styles from "./Curtain.module.scss";

const Curtain: React.FC = () => {
  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    setAnimated(true);
  }, []);

  return (
    <div className={styles.curtainContainer}>
      <div
        className={classnames(styles.curtain, {
          [styles.animation]: animated,
        })}
      ></div>
    </div>
  );
};

export default Curtain;
