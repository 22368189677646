import React from "react";
import { useIntl } from "react-intl";
import AnimatedButton from "../../../../ui/AnimatedButton/AnimatedButton";
import TransitionProvider from "../../../../ui/TransitionProvider/TransitionProvider";
import AspectRatioContainer from "../../../../ui/layout/AspectRatioContainer";

import styles from "./InfoPage.module.scss";

interface PropsInterface {
  text: string;
  onBtnClick: () => void;
  onBackClick?: () => void;
}

const InfoPage: React.FC<PropsInterface> = (props: PropsInterface) => {
  const { formatMessage: f } = useIntl();
  const { text, onBtnClick } = props;

  return (
    <AspectRatioContainer>
      <TransitionProvider>
        <div className={styles.infoPage}>
          <div className={styles.infoContent}>
            <h2 className={styles.infoTitle}>{f({ id: text })}</h2>
            <div>
              <AnimatedButton onClick={onBtnClick}>{f({ id: "btn.next" })}</AnimatedButton>
            </div>
          </div>
        </div>
      </TransitionProvider>
    </AspectRatioContainer>
  );
};

export default InfoPage;
