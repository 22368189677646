import { action, observable } from "mobx";

import AuthApiService from "../services/AuthApiService";
import AnalyticsService from "../services/AnalyticsApiService";
import { paths } from "../components/constants/paths";
import { ANALYTICS } from "../components/constants/analytics";

type RedirectUrl = string;

interface LoginInterface {
  data: string;
  redirectUrl: RedirectUrl;
}

export default class AuthStore {
  constructor(public api: AuthApiService, public analytics: AnalyticsService) {}

  @observable isLogged = false;
  @observable isInitialized = false;

  @action init(): void {
    if (this.api.init()) {
      this.isLogged = true;
    }
    this.isInitialized = true;
  }

  @action login(data: string): Promise<LoginInterface> {
    return this.api.login(data).then((res) => {
      this.isLogged = true;

      this.analytics.sendAnalytics({
        type: ANALYTICS.SPECIALIST,
        action: ANALYTICS.LOGIN,
      });

      return {
        data: res,
        redirectUrl: paths.default,
      };
    });
  }

  @action logout(): void {
    this.analytics.sendAnalytics({
      type: ANALYTICS.SPECIALIST,
      action: ANALYTICS.LOGOUT,
    });
    this.api.logout();
    this.isLogged = false;
  }
}
