// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3uNbPX_0QMuZPspUa3STl{display:flex;justify-content:space-between;flex-direction:column;height:100%;padding-top:1.42857rem}._3LFMbTRele1qHxCQIlAyQf{display:flex;justify-content:space-between}.LNEPkOl_sZE_wUvGBauBg{color:#25436d;font-weight:600;font-size:3.28571rem}._2Z8hdnb3zUqamoBnw_Zp8c{text-align:center;color:#25436d;font-weight:600;font-size:2.42857rem}.o_IvokJS1qgH9Jf0URf2b{width:40%;margin:0 auto;display:flex;flex-direction:column;justify-content:center;height:100%}.bB1LRjGd75c1WbfpWxERs{color:#8e8e8e;font-size:1.35714rem;line-height:152.5%;text-align:left;padding-top:3.42857rem;padding-bottom:11.71429rem}._2RGfxgzyY7OsAtepG2Hoec{text-align:center}\n", ""]);
// Exports
exports.locals = {
	"settingsTop": "_3uNbPX_0QMuZPspUa3STl",
	"settingsTopMain": "_3LFMbTRele1qHxCQIlAyQf",
	"settingsTopMainTitle": "LNEPkOl_sZE_wUvGBauBg",
	"settingTitle": "_2Z8hdnb3zUqamoBnw_Zp8c",
	"settingsContent": "o_IvokJS1qgH9Jf0URf2b",
	"settingsText": "bB1LRjGd75c1WbfpWxERs",
	"settingsContentButtonWrapper": "_2RGfxgzyY7OsAtepG2Hoec"
};
module.exports = exports;
