import React, { useMemo, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import LanguageSwitch from "../../languageSwitch/LanguageSwitch";
import userProfile from "../../../assets/images/user-edit-profile.svg";
import userSession from "../../../assets/images/user-session.svg";
import userStatistic from "../../../assets/images/user-statistic.svg";
import logo from "../../../assets/images/aba-stroke.svg";
import Label from "../../../../ui/Label/Label";
import stores from "../../../stores/";
import useModalStore from "../../../hooks/useModalStore";
import AnimatedComponent, { RunAnimation } from "../../../../ui/AnimatedComponent/AnimatedComponent";
import AspectRatio from "../../../../ui/layout/AspectRatioContainer";
import Loader from "../../loader/Loader";
import AnimatedButton from "../../../../ui/AnimatedButton/AnimatedButton";
import { paths, createPath } from "../../constants/paths";
import BaseLayoutAnimated from "../../../../ui/layout/BaseLayoutAnimated";

import styles from "./UserProfilePage.module.scss";

const UserProfilePage: React.FC = observer(() => {
  const { formatMessage: f } = useIntl();
  const { id } = useParams();
  const history = useHistory();
  const store = useMemo(() => stores.createUserStore(), []);
  const sessionStore = useMemo(() => stores.createSessionStore(id), [id]);
  const [loading, setLoading] = useState(false);
  const modal = useModalStore();

  useEffect(() => {
    store.init(id);
    sessionStore.getUserSessions();
  }, [store, sessionStore, id]);

  const lastSessionData = sessionStore?.userSessions?.data[0]?.createdAt
    ? new Date(sessionStore?.userSessions?.data[0].createdAt)
    : null;

  const onDeleteUser = (): void => {
    modal.showDeletePromptModal(() => store.deleteUser(id).then(() => history.push(paths.userList)));
  };

  const onSessionStart = (): void => {
    setLoading(true);
    sessionStore
      .onStartSession(id)
      .then(() => history.push(createPath.info(id)))
      .catch(() => setLoading(false));
  };

  const renderTop = (): React.ReactNode => {
    return (
      <div className={styles.userProfileTop}>
        <div className={styles.userProfileTopButtonWrapper}>
          <AnimatedButton variant="secondary" onClick={() => history.push(paths.userList)}>
            {f({ id: "btn.back" })}
          </AnimatedButton>
          <LanguageSwitch />
        </div>
        <div className={styles.userDetails}>
          <div className={styles.userDetailsTitle}>{store.user?.name}</div>
          <div className={styles.userDetailsSession}>
            <b>{f({ id: "page.profile.last.session" })}</b>
            <span> {lastSessionData ? format(lastSessionData, "dd.MM.yyyy") : "-"}</span>
          </div>
        </div>
      </div>
    );
  };

  if (!store.user || loading) {
    return (
      <AspectRatio>
        <Loader />
      </AspectRatio>
    );
  }

  return (
    <BaseLayoutAnimated top={renderTop()}>
      <div className={styles.userProfile}>
        <div className={styles.userMenu}>
          <div className={styles.userMenuBox}>
            <AnimatedComponent>
              {(runAnimation: RunAnimation) => (
                <div
                  className={styles.userMenuBoxImage}
                  onClick={runAnimation(() => history.push(createPath.addUser(store.user?.id)))}
                >
                  <img src={userProfile} alt="" />
                </div>
              )}
            </AnimatedComponent>
            <div>
              <span className={styles.userMenuTitle}>{f({ id: "page.profile.edit" })}</span>
            </div>
          </div>
          <div className={styles.userMenuBox}>
            <AnimatedComponent>
              {(runAnimation: RunAnimation) => (
                <div
                  className={styles.userMenuBoxImage}
                  onClick={runAnimation(() => store.user && history.push(createPath.statistic(store.user.id)))}
                >
                  <img src={userStatistic} alt="" />
                </div>
              )}
            </AnimatedComponent>
            <div>
              <span className={styles.userMenuTitle}>{f({ id: "page.profile.statistic" })}</span>
            </div>
          </div>
          <div className={styles.userMenuBox}>
            <AnimatedComponent>
              {(runAnimation: RunAnimation) => (
                <div className={styles.userMenuBoxImage} onClick={runAnimation(onSessionStart)}>
                  <img src={userSession} alt="" />
                </div>
              )}
            </AnimatedComponent>
            <div>
              <span className={styles.userMenuTitle}>{f({ id: "page.profile.start" })}</span>
            </div>
          </div>
        </div>
        <div className={styles.userDelete}>
          <span className={styles.userDeleteLabel} onClick={onDeleteUser}>
            <Label type="danger">{f({ id: "page.profile.delete" })}</Label>
          </span>
        </div>
        <div className={styles.logoBottom}>
          <img src={logo} alt="" />
        </div>
      </div>
    </BaseLayoutAnimated>
  );
});

export default UserProfilePage;
