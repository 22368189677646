// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._25P-sCseEZOQUaJuk5_2wd{display:flex;align-items:center;height:100%;flex-wrap:wrap;flex:14}._2zcz0Nu88N1ToF_vL_tSfB{display:flex;flex-wrap:wrap;justify-content:space-around;width:calc(100% - 2 *10rem);margin:0 auto}._30u6BkgZhX3f0-p2zcHtcT{display:inline-block;padding:1.42857rem .57143rem;cursor:pointer}._215G56_oJ_scVgjAJvdoMA{background:#25436d;color:#fff;padding:.85714rem .28571rem;display:inline-block;font-weight:600;font-size:1.78571rem;border-radius:.71429rem;position:absolute;top:-1.42857rem;width:3.14286rem;text-align:center}.ineomNjIdSofSyPTrcbmK{display:flex;align-items:center;justify-content:center;width:14.28571rem;height:16.14286rem;position:relative}.ineomNjIdSofSyPTrcbmK img{max-width:100%;max-height:100%}._1QHXas102drv5pu7EqkcYt{background:#fff;overflow:hidden;width:100%;height:100%;border:.14286rem solid #eaeaea;box-shadow:.71429rem .71429rem 2.5rem rgba(116,116,116,0.08);border-radius:.71429rem;display:flex;align-items:center;justify-content:center;padding:.42857rem}._1QHXas102drv5pu7EqkcYt img{width:100%;height:100%;max-width:none;max-height:none;object-fit:cover;border-radius:.35714rem}._2cMZHQo3HRxuexm51Hx09A{font-weight:600;padding-top:1.07143rem;font-size:1.35714rem;color:#25436d;text-align:center;width:14.28571rem;overflow:hidden;white-space:normal;text-overflow:ellipsis}._3wVN1AKg3bTuOo1xX8znaI{cursor:not-allowed}._3wVN1AKg3bTuOo1xX8znaI ._2cMZHQo3HRxuexm51Hx09A{color:#bfbfbf}._3wVN1AKg3bTuOo1xX8znaI ._215G56_oJ_scVgjAJvdoMA{background:#eaeaea;color:#cfcfcf;border:.14286rem solid #bfbfbf}._3wVN1AKg3bTuOo1xX8znaI img{width:50%;height:50%;object-fit:initial}._1cNVA02kmV6Y7gxrkMbzi-{flex:1}._1cNVA02kmV6Y7gxrkMbzi- img{width:100%;cursor:pointer}.n5mXZRfWutm9SrnAUul6r{opacity:0.5;pointer-events:none}\n", ""]);
// Exports
exports.locals = {
	"albumItemWrapper": "_25P-sCseEZOQUaJuk5_2wd",
	"albumItemsContainer": "_2zcz0Nu88N1ToF_vL_tSfB",
	"albumItem": "_30u6BkgZhX3f0-p2zcHtcT",
	"albumItemNumber": "_215G56_oJ_scVgjAJvdoMA",
	"albumItemImgWrapper": "ineomNjIdSofSyPTrcbmK",
	"albumItemImg": "_1QHXas102drv5pu7EqkcYt",
	"albumItemName": "_2cMZHQo3HRxuexm51Hx09A",
	"blocked": "_3wVN1AKg3bTuOo1xX8znaI",
	"albumArrow": "_1cNVA02kmV6Y7gxrkMbzi-",
	"disabled": "n5mXZRfWutm9SrnAUul6r"
};
module.exports = exports;
