import AbstractHttpService from "./AbstractHttpService";
import { Session, GamePlay, FinishGameData } from "../interfaces/Session";
import { ApiHttpResponseWithPagination } from "../interfaces/AbstractHttpService";
import { PaginationConfig } from "../interfaces/AbstractHttpService";

class SessionApiService extends AbstractHttpService {
  getActiveSession(id: string): Promise<Session> {
    return this.http
      .get(`api/specialist/v1/patients/${id}/active_session`)
      .then((response) => this.resolve<Session>(response))
      .catch(this.reject);
  }

  getUserSessions(id: string, config: PaginationConfig): Promise<ApiHttpResponseWithPagination<Session[]>> {
    const query = this.createPaginationQuery(config);

    return this.http
      .get(`api/specialist/v1/patients/${id}/sessions?${query}`)
      .then((response) => this.resolveWithPaginationHeaders<Session[]>(response))
      .catch(this.reject);
  }

  createNewSession(id: string): Promise<Session> {
    return this.http
      .post(`api/specialist/v1/patients/${id}/active_session`)
      .then((response) => this.resolve<Session>(response))
      .catch(this.reject);
  }

  finishSession(id: string): Promise<Session> {
    return this.http
      .put(`api/specialist/v1/sessions/${id}/finish`)
      .then((response) => this.resolve<Session>(response))
      .catch(this.reject);
  }

  finishGameplay(sessionId: string, gameplayId: string, data: FinishGameData): Promise<GamePlay> {
    return this.http
      .put(`api/specialist/v1/sessions/${sessionId}/gameplays/${gameplayId}/finish`, {
        body: JSON.stringify(data),
      })
      .then((response) => this.resolve<GamePlay>(response))
      .catch(this.reject);
  }

  startGameplay(sessionId: string): Promise<GamePlay> {
    return this.http
      .post(`api/specialist/v1/sessions/${sessionId}/gameplays`)
      .then((response) => this.resolve<GamePlay>(response))
      .catch(this.reject);
  }
}

export default SessionApiService;
