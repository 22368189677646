import React, { useMemo, useEffect } from "react";
import { toJS } from "mobx";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";

import AspectRatioContainer from "../../../../ui/layout/AspectRatioContainer";
import AnimatedButton from "../../../../ui/AnimatedButton/AnimatedButton";
import TransitionProvider from "../../../../ui/TransitionProvider/TransitionProvider";
import Label from "../../../../ui/Label/Label";
import rewards from "../../../assets/images/rewards.svg";
import stores from "../../../stores/";
import { createPath } from "../../constants/paths";

import styles from "./NextProgramPage.module.scss";

const NextProgramPage: React.FC = observer(() => {
  const { formatMessage: f } = useIntl();
  const { userId } = useParams();
  const history = useHistory();
  const language = stores.language.language;

  const store = useMemo(() => stores.createNextProgramStore(userId), [userId]);

  useEffect(() => {
    sessionStorage.setItem("initializedGame", "false");
  }, []);

  useEffect(() => {
    if (store.finishedSessionData) {
      const sessionId = store.session?.id;
      const data = toJS(store.finishedSessionData);

      if (sessionId) {
        history.push({
          pathname: createPath.statistic(userId, sessionId),
          state: data,
        });
      }
    }

    if (store.isSessionFinished) {
      history.push(createPath.user(userId));
    }
  }, [store.isSessionFinished, store.finishedSessionData, store.session?.id, history, userId]);

  const renderTop = (): React.ReactNode => {
    return (
      <div className={styles.nextProgramTop}>
        <div className={styles.btnAwards} onClick={() => history.push(createPath.albums(userId))}>
          <img className={styles.rewardsImg} src={rewards} alt="" />
          <span className={styles.awardsText}>{f({ id: "page.next.rewards" })}</span>
        </div>
      </div>
    );
  };

  const cancelSession = (): void => {
    store.cancelSession(userId);
    history.push(createPath.infoSpecjalist(userId));
  };

  return (
    <AspectRatioContainer>
      <TransitionProvider>
        <div className={styles.nextProgramPage}>
          {renderTop()}

          <div>
            <h2 className={styles.mainTitle}>{f({ id: "page.next.title" })}</h2>
            <div className={styles.nextProgramTileWrapper}>
              <div className={styles.nextProgramTile}>
                <img src={store.session?.currentGameplay?.game.iconUrl} />
              </div>
              <h4 className={styles.tileTitle}>{store.session?.currentGameplay?.game.translations[language]?.name}</h4>
            </div>
            <div className={styles.btnWrapper}>
              <AnimatedButton onClick={() => history.push(`/patient/${userId}/gameplay`)}>Start</AnimatedButton>
            </div>

            <div className={styles.labelWrapper}>
              <span className={styles.labelBtn} onClick={cancelSession}>
                <Label type="danger">{f({ id: "page.next.label" })}</Label>
              </span>
            </div>
          </div>
        </div>
      </TransitionProvider>
    </AspectRatioContainer>
  );
});

export default NextProgramPage;
