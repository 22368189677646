import React from "react";
import classnames from "classnames";
import { observer } from "mobx-react";
import useModalStore from "../hooks/useModalStore";
import config from "./ModalConfig";
import style from "./ModalContainer.module.scss";

const ModalContainer: React.FC = observer(() => {
  const modal = useModalStore();

  const renderModal = (): React.ReactNode => {
    const Component = modal.type && config[modal.type];

    if (Component) {
      return <Component {...modal.params} onHide={() => modal.hide()} />;
    }
    return null;
  };

  return (
    <div
      className={classnames(style.modalContainer, {
        [style.modalOpened]: modal.isOpen,
      })}
    >
      {renderModal()}
    </div>
  );
});

export default ModalContainer;
