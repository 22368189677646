import React from "react";
import { useIntl } from "react-intl";
import BaseModalLayout from "../../ui/layout/BaseModalLayout";
import AnimatedButton from "../../ui/AnimatedButton/AnimatedButton";
import Input from "../../ui/Input/Input";

import style from "./BackModal.module.scss";

interface BackModalProps {
  onSubmit?: () => void;
  onHide?: () => void;
}

const BackModal: React.FC<BackModalProps> = (props: BackModalProps) => {
  const { onSubmit, onHide } = props;
  const { formatMessage: f } = useIntl();

  const renderTop = (): React.ReactNode => {
    return (
      <AnimatedButton onClick={onHide} variant="secondary">
        {f({ id: "modal.back.btn.cancel" })}
      </AnimatedButton>
    );
  };

  return (
    <BaseModalLayout top={renderTop()}>
      <div className={style.modalBody}>
        <div className={style.modalBodyWrapper}>
          <h2 className={style.modalTitle}>{f({ id: "modal.back.title" })}</h2>
          <Input placeholder="PIN" type="password" />
          <div>
            <AnimatedButton onClick={onSubmit}>{f({ id: "modal.back.btn.ok" })}</AnimatedButton>
          </div>
        </div>
      </div>
    </BaseModalLayout>
  );
};

export default BackModal;
