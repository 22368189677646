import { action, observable } from "mobx";

export default class LanguageStore {
  @observable language = localStorage.getItem("language") || "pl";

  @action changeLanguage(language: string): void {
    this.language = language;
    localStorage.setItem("language", language);
  }
}
