// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../node_modules/css-loader/dist/cjs.js!normalize.css/normalize.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;800&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;800&display=swap);"]);
// Module
exports.push([module.id, "*,*:before,*:after{box-sizing:border-box;outline:none}html,body{width:100%;margin:0;padding:0;font-family:\"Poppins\",sans-serif;font-weight:400;font-size:14px;color:#000}\n", ""]);
// Exports
module.exports = exports;
