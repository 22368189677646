import AbstractHttpService from "./AbstractHttpService";
import HttpConnectorService from "./HttpConnectorService";
import { CLIENT_ID, CLIENT_SECRET } from "appConfig";

import { ResponseAuthData } from "../interfaces/AuthApiService";
import ky from "ky";

export const STORE_TOKEN_KEY = "authToken";
export const REFRESH_TOKEN_KEY = "refreshToken";
const grandType = "http://specialist/key";

class AuthApiService extends AbstractHttpService {
  constructor(http: HttpConnectorService) {
    super(http);

    http.extend({
      hooks: {
        afterResponse: [
          async (request, options, response) => {
            if (response.status === 204) {
              return;
            }

            const body = await response.json();

            if (response.status === 401 && body.error === "invalid_grant") {
              const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

              if (!refreshToken) {
                this.logout();
              }
              try {
                const token = await this.refreshRequest();
                request.headers.set("Authorization", `Bearer ${token}`);
                return ky(request);
              } catch (error) {
                this.logout();
              }
            }
            return;
          },
        ],
      },
    });
  }

  private setAuthorizationHeader(token: string | null): void {
    if (token) {
      this.http.addHeader("Authorization", `Bearer ${token}`);
    } else {
      this.http.removeHeader("Authorization");
    }
  }

  onAuth(loginData: ResponseAuthData): string {
    this.setAuthorizationHeader(loginData.access_token);
    localStorage.setItem(STORE_TOKEN_KEY, loginData.access_token);
    localStorage.setItem(REFRESH_TOKEN_KEY, loginData.refresh_token);

    return loginData.access_token;
  }

  init(): boolean {
    const token = localStorage.getItem(STORE_TOKEN_KEY);

    if (!token) {
      return false;
    }

    this.setAuthorizationHeader(token);
    return true;
  }

  login(data: string): Promise<string> {
    const formData = new FormData();
    formData.append("key", data);
    formData.append("grant_type", grandType);
    formData.append("client_id", CLIENT_ID);
    formData.append("client_secret", CLIENT_SECRET);

    return this.http
      .post("oauth/v2/token", { body: formData })
      .then(this.resolve)
      .then((data) => this.onAuth(data as ResponseAuthData))
      .catch(this.reject);
  }

  logout(): void {
    localStorage.removeItem(STORE_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    this.setAuthorizationHeader(null);
  }

  refreshRequest(): Promise<string> {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

    if (!refreshToken) {
      return Promise.reject();
    }

    const formData = new FormData();
    formData.append("grant_type", "refresh_token");
    formData.append("refresh_token", refreshToken);
    formData.append("client_id", CLIENT_ID);
    formData.append("client_secret", CLIENT_SECRET);

    return this.http
      .post("oauth/v2/token", { body: formData })
      .then(this.resolve)
      .then((data) => this.onAuth(data as ResponseAuthData));
  }
}

export default AuthApiService;
