import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import classnames from "classnames";
import { useParams, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

import AlbumsLayout from "./AlbumsLayout";
import Loader from "../../loader/Loader";

import blocked from "../../../assets/images/blocked.svg";

import arrRight from "../../../assets/images/arr-right.svg";
import arrLeft from "../../../assets/images/arr-left.svg";
import stores from "../../../stores";
import { createPath } from "../../constants/paths";
import { CategoryItemsType } from "../../../stores/AlbumStore";

import styles from "./AlbumItemPage.module.scss";

interface IndexedCategoryItemsType extends CategoryItemsType {
  index: number;
}

const AlbumsCategoryPage: React.FC = observer(() => {
  const { categoryId, userId, id } = useParams();
  const history = useHistory();
  const { formatMessage: f } = useIntl();
  const store = useMemo(() => stores.createAlbumStore(), []);
  const index = id - 1;

  useEffect(() => {
    store.getCategoryItems(userId, categoryId);
  }, [store, userId, categoryId]);

  const categoryItems = store.categoryItems.length > 0 ? store.categoryItems : null;
  const language = stores.language.language;

  const actualItem = categoryItems?.[index];

  const getNextAvailable = (): IndexedCategoryItemsType | undefined => {
    const startingPoint = index + 1;
    return categoryItems
      ?.slice(startingPoint)
      .map((item, index) => ({
        ...item,
        index: index + startingPoint,
      }))
      .find((item) => item.data);
  };

  const getPrevAvailable = (): IndexedCategoryItemsType | undefined => {
    return categoryItems
      ?.slice(0, index)
      .map((item, index) => ({
        ...item,
        index,
      }))
      .reverse()
      .find((item) => item.data);
  };

  const nextIndex = getNextAvailable()?.index;
  const prevIndex = getPrevAvailable()?.index;

  const isNext = nextIndex !== undefined;
  const isPrev = prevIndex !== undefined;

  const renderItem = (): React.ReactNode => {
    return (
      <div className={styles.albumWrapper}>
        <div
          onClick={() =>
            prevIndex !== undefined && history.push(createPath.albumsItem(userId, categoryId, prevIndex + 1))
          }
          className={classnames(styles.albumArrow, {
            [styles.disabled]: !isPrev,
          })}
        >
          <img src={arrLeft} />
        </div>
        <div className={styles.albumItemWrapper}>
          <div className={styles.albumItemNumberWrapper}>
            <span
              className={classnames(styles.albumItemNumber, {
                [styles.albumItemNumberDisabled]: !actualItem?.data,
              })}
            >
              {id}
            </span>
          </div>
          <div className={styles.albumItemContent}>
            <div className={styles.albumItemContentImageWrapper}>
              <div
                className={classnames(styles.albumItemContentImage, {
                  [styles.blocked]: !actualItem?.data,
                })}
              >
                <img src={actualItem?.data ? actualItem.data.imageUrl : blocked} alt="" key={index} />
              </div>
            </div>
            <div className={styles.albumItemContentTextWrapper}>
              <h3 className={styles.albumItemTitle}>
                {actualItem?.data
                  ? actualItem?.data.translations[language].name
                  : f({ id: "album.award.blocked.title" })}
              </h3>
              <p>
                {actualItem?.data
                  ? actualItem?.data.translations[language].description
                  : f({ id: "album.award.blocked.text" })}
              </p>
            </div>
          </div>
        </div>
        <div
          onClick={() =>
            nextIndex !== undefined && history.push(createPath.albumsItem(userId, categoryId, nextIndex + 1))
          }
          className={classnames(styles.albumArrow, {
            [styles.disabled]: !isNext,
          })}
        >
          <img src={arrRight} />
        </div>
      </div>
    );
  };

  return (
    <AlbumsLayout
      onBack={() => history.push(createPath.albumsCategory(userId, categoryId))}
      imageSrc={store.currentCategory?.thumbnailUrl}
      categoryName={store.currentCategory?.translations[language].name}
    >
      {actualItem ? renderItem() : <Loader />}
    </AlbumsLayout>
  );
});

export default AlbumsCategoryPage;
