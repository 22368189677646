// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._13YaoQ6rVx62zsj3pPzpM0{display:flex;justify-content:flex-end}._13XDarvvu_zmWix8TlfASW{position:relative}._3EZmSOAPnyG3dfUMwhilb8{position:absolute;right:0;border:2px solid transparent}.eSl-yJtXYhsKHBZO2YE_o{border:2px solid #eaeaea;box-shadow:10px 10px 35px rgba(116,116,116,0.08);border-radius:10px}._22WdeYa88rgNnxsND2_yOd{width:100%;max-height:0;overflow:hidden;transition:max-height 100ms}._22WdeYa88rgNnxsND2_yOd :hover{background:#ecf2fa}._2DjZYl5RKAPVFhHFSN12L7{height:auto;max-height:35.71429rem}.K7dPgflvriipIo-YSKinW{min-width:2.85714rem;display:inline-block;align-items:center;padding-right:1.07143rem;display:flex;align-items:center;justify-content:center;color:#25436d;font-weight:600;font-size:1.42857rem}.K7dPgflvriipIo-YSKinW img{width:80%}._2xI9tnvGyOeA_wNq9mXXav{width:5.28571rem}._1RCf23U7TD_ZG61i8fQZE7{display:flex;cursor:pointer;padding:.71429rem 1.07143rem}\n", ""]);
// Exports
exports.locals = {
	"languageContainer": "_13YaoQ6rVx62zsj3pPzpM0",
	"languageSwitch": "_13XDarvvu_zmWix8TlfASW",
	"languageSwitchWrapper": "_3EZmSOAPnyG3dfUMwhilb8",
	"languageSwitchOpened": "eSl-yJtXYhsKHBZO2YE_o",
	"switchMenuDropdown": "_22WdeYa88rgNnxsND2_yOd",
	"dropdownOpen": "_2DjZYl5RKAPVFhHFSN12L7",
	"languageIcoNameWrapper": "K7dPgflvriipIo-YSKinW",
	"menuImgFlag": "_2xI9tnvGyOeA_wNq9mXXav",
	"languageSwitchMenu": "_1RCf23U7TD_ZG61i8fQZE7"
};
module.exports = exports;
