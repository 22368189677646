import React, { useEffect, useMemo, useState } from "react";
import classnames from "classnames";
import { observer } from "mobx-react";
import stores from "../../stores";
import Curtain from "../curtain/Curtain";
import { useIntl } from "react-intl";
import AnimatedButton from "../../../ui/AnimatedButton/AnimatedButton";

import styles from "./Award.module.scss";

import applausePl from "../../assets/sounds/applaus/pl.mp3";
import applauseDe from "../../assets/sounds/applaus/de.mp3";

const applauseUrls = {
  de: applauseDe,
  pl: applausePl,
} as const;

type LanguageSlug = keyof typeof applauseUrls;

interface AwardProps {
  userId: string;
  onClose?: () => void;
}

const Award: React.FC<AwardProps> = observer((props: AwardProps) => {
  const store = useMemo(() => stores.createAwardsStore(), []);
  const [initAnimation, setInitAnimated] = useState(false);
  const { formatMessage: f } = useIntl();

  const language = stores.language.language;
  const url = applauseUrls[(language as LanguageSlug) || "pl"] ?? applauseUrls["pl"];
  const audio = useMemo(() => new Audio(url), [url]);

  useEffect(() => {
    audio.play();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    store.getAwards();
  }, [store]);

  useEffect(() => {
    setTimeout(() => {
      setInitAnimated(true);
    }, 1000);
  }, []);

  const renderAwardsCategories = (): React.ReactNode => {
    if (!initAnimation) {
      return null;
    }
    return (
      <div className={styles.awardImagesWrapper}>
        {store.awardsCategory?.map((item, index) => {
          return (
            <div key={index} className={styles.awardItemWrapper}>
              <div className={styles.awardItem} onClick={() => store.drawUserAwards(props.userId, item.id)}>
                <img src={item.thumbnailUrl} alt="" />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderAwardContent = (): React.ReactNode => {
    return (
      <React.Fragment>
        <div
          className={classnames(styles.awardPage, styles.awardPageShine, styles.drawedAward, {
            [styles.visible]: store.drawedAward,
          })}
        >
          {props.onClose && (
            <div className={styles.btnBackWrapper}>
              <AnimatedButton variant="secondary" onClick={props.onClose}>
                {f({ id: "btn.back" })}
              </AnimatedButton>
            </div>
          )}
          <div className={styles.unlockedAward}>
            <div className={styles.unlockedImage}>
              <img src={store.drawedAward?.imageUrl} alt="" />
            </div>
            <div className={styles.unlockedText}>
              <p className={styles.unlockedAwardTitle}>{store.drawedAward?.translations[language].name}</p>
              <p>{store.drawedAward?.translations[language].description}</p>
            </div>
          </div>
        </div>

        <div
          className={classnames(styles.awardPage, styles.categoryAward, {
            [styles.visible]: !store.drawedAward,
          })}
        >
          <h2
            className={classnames(styles.awardTitle, {
              [styles.visible]: initAnimation,
            })}
          >
            {f({ id: "award.draw.text" })}
          </h2>

          {renderAwardsCategories()}
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className={styles.awardContainer}>
      <Curtain />
      {renderAwardContent()}
    </div>
  );
});

export default Award;
