import { action, observable } from "mobx";

import MODAL_TYPES from "../modals/ModalTypes";
import MODAL_CONFIG from "../modals/ModalConfig";

type ModalType = typeof MODAL_TYPES[keyof typeof MODAL_TYPES];
type ModalProps<T extends ModalType> = React.ComponentProps<typeof MODAL_CONFIG[T]>;

export default class ModalStore {
  @observable type: ModalType | null = null;
  @observable isOpen = false;
  @observable params = {};

  @action show<T extends ModalType>(type: T, params: ModalProps<T>): void {
    this.type = type;
    this.isOpen = true;
    this.params = params;
  }

  @action hide(): void {
    this.type = null;
    this.isOpen = false;
  }

  @action showDeletePromptModal(onDelete: () => void): void {
    this.show(MODAL_TYPES.DELETE_PROMPT_MODAL, { onDelete });
  }

  @action showBackModal(onSubmit: () => void): void {
    this.show(MODAL_TYPES.BACK_MODAL, { onSubmit });
  }
}
