// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._33Ya5H-0eiGj0JA0SlnyLF{padding:0 !important;position:absolute;overflow:hidden;bottom:0;height:14.28571rem;width:80%;left:10%;pointer-events:none}._33Ya5H-0eiGj0JA0SlnyLF.H63A4u4H4Xjlb08hztsOA{pointer-events:all}._33Ya5H-0eiGj0JA0SlnyLF.H63A4u4H4Xjlb08hztsOA ._2WUNcdkIDNnigjAx3YZVGH{bottom:7.14286rem}._1GRga-T9fK_nWCoypQAXm9{display:flex;justify-content:space-between;position:absolute;width:100%;top:0;margin-top:-8px}._2Wq_Xy6m9LLGeHjJ79BAOs{width:2.78571rem;height:2.78571rem;background:#e9ebf4;border-radius:50%}._2WUNcdkIDNnigjAx3YZVGH{height:1.85714rem;width:calc(100% - 2 * 1.14286rem);margin:0 1.14286rem;background:#e9ebf4;border-radius:1.85714rem;overflow:hidden;position:absolute;bottom:-14.28571rem;transition:bottom 0.5s;box-shadow:0 0 0 1.14286rem #fff}._2FWrdtq6zL1BLzURv2zV1l{height:100%;width:0%;background:#4fd46c;transition:width 0.5s}\n", ""]);
// Exports
exports.locals = {
	"progressBarWrapper": "_33Ya5H-0eiGj0JA0SlnyLF",
	"visible": "H63A4u4H4Xjlb08hztsOA",
	"progressbar": "_2WUNcdkIDNnigjAx3YZVGH",
	"pointsWrapper": "_1GRga-T9fK_nWCoypQAXm9",
	"point": "_2Wq_Xy6m9LLGeHjJ79BAOs",
	"progress": "_2FWrdtq6zL1BLzURv2zV1l"
};
module.exports = exports;
