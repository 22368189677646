// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/blask.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "._3cKgQ6mdNJV5636SKtSXTz{display:flex;height:100%;align-items:center;justify-content:center;flex-wrap:wrap;padding:2.14286rem}.KZxVr6etnHggnRBqslKk4{display:inline-block;cursor:pointer;position:relative;padding:3.28571rem 1.42857rem 0;flex-basis:33%}.KZxVr6etnHggnRBqslKk4:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:contain;background-position:center;content:\"\";position:absolute;top:0;left:0;width:100%;height:100%}._3VbkCKUYGdvM3tXSCnlnL_{background:#fff;border:2px solid #eaeaea;box-shadow:.71429rem .71429rem 2.5rem rgba(116,116,116,0.08);border-radius:10px;width:20.5rem;height:16.14286rem;padding:2.5rem;display:flex;align-items:center;justify-content:center;position:relative;margin:0 auto}._3VbkCKUYGdvM3tXSCnlnL_ img{max-width:100%;max-height:100%}._1eYytVRiVZ6VMvrijeXG9R{font-weight:600;padding-top:1.07143rem;font-size:1.57143rem;color:#25436d;text-align:center}._2CXY7RTX25jagw9Fs0mdmS{text-align:center;margin-top:-2.14286rem;position:relative}._104CVHnN8kESAdR3ZVkjMf{background:#25436d;color:#fff;padding:1rem;display:inline-block;font-weight:600;font-size:1.78571rem;border-radius:.71429rem}\n", ""]);
// Exports
exports.locals = {
	"albumItemWrapper": "_3cKgQ6mdNJV5636SKtSXTz",
	"albumItem": "KZxVr6etnHggnRBqslKk4",
	"albumItemImgWrapper": "_3VbkCKUYGdvM3tXSCnlnL_",
	"albumItemName": "_1eYytVRiVZ6VMvrijeXG9R",
	"albumItemAmountWrapper": "_2CXY7RTX25jagw9Fs0mdmS",
	"albumItemAmount": "_104CVHnN8kESAdR3ZVkjMf"
};
module.exports = exports;
