export const ANALYTICS = {
  SPECIALIST: "specialist_action",
  PATIENT: "patient_action",
  LOGIN: "zalogowanie",
  LOGOUT: "wylogowanie",
  PROFILE_LIST: "ekran_lista_profilow",
  PROFILE_ADD: "stworzenie_profilu",
  PROFILE_EDIT: "edytowal_profil",
  STATISTIC: "ekran_statystyk",
  SESSION_START: "start_sesji",
  SESSION_STOP: "stop_sesji",
  SESSION_FINISH: "koniec_sesji",
  SESSION_RESUMPTION: "wznowienie_sesji",
  PROGRAM_START: "start_programu",
  PROGRAM_END: "koniec_programu",
  PROGRAM_STOP: "stop_programu",
};
