import AbstractHttpService from "./AbstractHttpService";
import { Analytics } from "../interfaces/Analytics";

class AnalyticsApiService extends AbstractHttpService {
  sendAnalytics(data: Analytics): Promise<unknown> {
    return this.http.post("api/specialist/v1/analytics/events", { body: JSON.stringify(data) }).catch(this.reject);
  }
}

export default AnalyticsApiService;
