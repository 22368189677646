import AppWebConnector from "../../engine-core/services/AppWebConnector";
import SessionApiService from "./SessionApiService";
import AnalyticsApiService from "./AnalyticsApiService";
import GameApiService from "./GameApiService";
import AwardApiService from "./AwardsApiService";
import GameplayApiService from "./GameplaysApiService";
import ProgressBarStore from "../stores/ProgressBarStore";
import ToastsStore from "../stores/ToastsStore";

import SetStoreMessage from "../../engine-core/interfaces/SetStoreMessage";
import CoinAddRequest from "../../engine-core/interfaces/CoinAddRequest";
import EndGameMessage from "../../engine-core/interfaces/EndGameMessage";
import EndQuestionMessage from "../../engine-core/interfaces/EndQuestionMessage";
import { GameData, GlobalData, RuntimeData, StorageData } from "../../engine-core/interfaces/InitMessagePayload";
import { Session } from "../interfaces/Session";
import { BoostPoints } from "../interfaces/Award";
import { ANALYTICS } from "../components/constants/analytics";

export interface GameManagerConfig {
  session: Session;
  userId: string;
  iframe: {
    window: Window;
    origin: string;
  };
  initData: {
    storageData: Partial<StorageData>;
    runtimeData: RuntimeData;
    globalData: GlobalData;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gameData: GameData<any, any>;
  };
  onEndSession: (session: Session) => void;
  onNextProgram: () => void;
  onStartAward: (resolve: () => void) => void;
  onEndAward: () => void;
  onCloseGame: () => void;
}

export default class GameManager {
  private connector: AppWebConnector;

  constructor(
    public config: GameManagerConfig,
    public sessionApi: SessionApiService,
    public gameApi: GameApiService,
    public awardsApi: AwardApiService,
    public gameplayApi: GameplayApiService,
    public progressbar: ProgressBarStore,
    public toasts: ToastsStore,
    public analyticsApi: AnalyticsApiService
  ) {
    this.connector = new AppWebConnector({
      window: config.iframe.window,
      origin: config.iframe.origin,
    });

    // Here we can have 2 cases:
    //
    // 1. Before run this constructor message "ready" was fired
    // 2. After run this constructor message "ready" will be fired
    //
    // To handle case 1. we instant call `this.onReady()`
    this.onReady();
    this.connector.onReady(this.onReady);
    this.connector.onSetStore(this.onSetStore);
    this.connector.onCoinAdd(this.onCoinAdd);
    this.connector.onEndGame(this.onEndGame);
    this.connector.onEndQuestion(this.onEndQuestion);
    this.connector.onCloseGame(this.config.onCloseGame);
  }

  /**
   * Run on Engine was ready
   */
  private onReady = (): void => {
    const { userId } = this.config;
    this.fetchInitialPoints(userId);
    this.connector.init(this.config.initData);
  };

  /**
   * Run when Engine want to save some data
   */
  private onSetStore = async (e: SetStoreMessage<StorageData>): Promise<void> => {
    const userId = this.config.userId;
    const gameId = this.config.session.currentGameplay.game.id;

    await this.gameApi.setGameData(userId, gameId, e.payload);
  };

  private fetchInitialPoints = async (userId: string): Promise<void> => {
    const { max, amount } = await this.awardsApi.getBoostPoints(userId);
    return this.progressbar.setProgress(amount / max, true);
  };

  private addPoints = async (userId: string, data: CoinAddRequest["request"]): Promise<BoostPoints> => {
    try {
      return await this.awardsApi.addBoostPoints(userId, data.payload);
    } catch (e) {
      if (e.error === "MaxBoostPointsReached") {
        return {
          awardReached: true,
          amount: 100,
          max: 100,
        };
      }
      throw e;
    }
  };

  /**
   * Run when Engine want to add some coins
   */
  private onCoinAdd = async (data: CoinAddRequest["request"]): Promise<void> => {
    const userId = this.config.userId;

    try {
      const pointsVault = await this.addPoints(userId, data);
      await this.progressbar.setProgress(pointsVault.amount / pointsVault.max);

      if (pointsVault.awardReached) {
        return new Promise((resolve) => {
          this.config.onStartAward(() => {
            this.progressbar.setProgress(0, true);
            resolve();
          });
        });
      }
    } catch (e) {
      this.toasts.add({
        title: e.error,
        message: e.error_description,
        type: "error",
      });
      return;
    }
  };

  /**
   * Run when Engine end game
   */
  private onEndGame = async (data: EndGameMessage): Promise<void> => {
    const sessionId = this.config.session.id;
    const gameplayId = this.config.session.currentGameplay.id;
    const gameId = this.config.session.currentGameplay.game.id;

    // 1. Finish actual gameplay
    this.analyticsApi.sendAnalytics({
      type: ANALYTICS.PATIENT,
      action: ANALYTICS.PROGRAM_END,
      patient: this.config.userId,
      game: gameId,
    });

    await this.sessionApi.finishGameplay(sessionId, gameplayId, data.payload);

    try {
      // 2. Start next gameplay
      await this.sessionApi.startGameplay(sessionId);
      this.config.onNextProgram();
    } catch (e) {
      if (e.error === "NoGamesAvailable") {
        // 3. If can't start next gameplay because there isn't next gameplay - Finish session
        this.analyticsApi.sendAnalytics({
          type: ANALYTICS.PATIENT,
          action: ANALYTICS.SESSION_FINISH,
          patient: this.config.userId,
        });
        const sessionData = await this.sessionApi.finishSession(sessionId);
        this.config.onEndSession(sessionData);
      }
    }
  };

  /**
   * Run when Engine end one question
   */
  private onEndQuestion = (e: EndQuestionMessage): void => {
    const sessionId = this.config.session.id;
    const gameplayId = this.config.session.currentGameplay.id;

    this.gameplayApi.postGameplayAnswer(sessionId, gameplayId, e.payload);
  };

  public destroy(): void {
    this.connector.destroy();
  }
}
