// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2Wg81Q43gfVXImoXnQ2mYb{width:100vw;height:100vh;display:flex;justify-content:center;align-items:center;background:#fff}._3-kp27Ucy0f6NoJ9PvKENg{background:#fff}._270MNmrm1DdfyyNydM2B6H ._2Wg81Q43gfVXImoXnQ2mYb{background:transparent}._270MNmrm1DdfyyNydM2B6H ._3-kp27Ucy0f6NoJ9PvKENg{background:transparent;position:relative}\n", ""]);
// Exports
exports.locals = {
	"mainContainer": "_2Wg81Q43gfVXImoXnQ2mYb",
	"wrapper": "_3-kp27Ucy0f6NoJ9PvKENg",
	"toastsContainer": "_270MNmrm1DdfyyNydM2B6H"
};
module.exports = exports;
