import React from "react";
import classnames from "classnames";
import { observer } from "mobx-react";
import { Toasts } from "../../interfaces/Toasts";
import close from "../../assets/images/close.svg";

import styles from "./Toast.module.scss";

interface ToastProps {
  toast: Toasts;
  onClose: (id: number) => void;
}

const Toasts: React.FC<ToastProps> = observer((props) => {
  const { type, title, message, id } = props.toast;

  const onClick = (): void => {
    if (id) {
      props.onClose(id);
    }
  };

  return (
    <div onClick={onClick} className={classnames(styles.toast, styles[type])}>
      <div className={styles.title}>
        <span>{title}</span>
        <img src={close} />
      </div>
      {message && <div className={styles.message}>{message}</div>}
    </div>
  );
});

export default Toasts;
