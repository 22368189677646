import React, { useEffect } from "react";
import useWindowSize from "./useWindowSize";

import style from "./AspectRatioContainer.module.scss";

interface screenBaseSize {
  width: number;
  height: number;
}

interface AspectRatioContainerProps {
  children: React.ReactNode;
  ratio?: number;
  fontBaseSize?: number;
  screenBaseSize?: screenBaseSize;
}

interface ScreenSizeInterface {
  width: number;
  height: number;
}

const AspectRatioContainer: React.FC<AspectRatioContainerProps> = ({
  children,
  ratio = 16 / 9,
  fontBaseSize = 14,
  screenBaseSize = {
    //originally design was prepared for 4:3, on client request we changed that to 16:9,
    //we keep original design height, and stretched layout in width, therefore we changed
    //original width: 1366
    width: (1024 * 16) / 9,
    height: 1024,
  },
}: AspectRatioContainerProps) => {
  const windowSize = useWindowSize();

  const convertScreenSize = (): ScreenSizeInterface => {
    const screenWidth = windowSize.width;
    const convertedHeight = windowSize.width / ratio;

    const screenHeight = windowSize.height;
    const convertedWidth = windowSize.height * ratio;

    const width = convertedWidth < screenWidth ? convertedWidth : screenWidth;
    const height = convertedHeight < screenHeight ? convertedHeight : screenHeight;

    return {
      width,
      height,
    };
  };

  const convertFontSize = (screenWidth: number): number => {
    const fontSize = (screenWidth * fontBaseSize) / screenBaseSize.width;
    return fontSize;
  };

  const convertedScreenSize = convertScreenSize();

  useEffect(() => {
    const font = convertFontSize(convertedScreenSize.width);
    const body = document.getElementsByTagName("html")[0];
    body.style.fontSize = `${font}px`;
  });

  return (
    <div className={style.mainContainer}>
      <div className={style.wrapper} style={{ width: convertedScreenSize.width, height: convertedScreenSize.height }}>
        {children}
      </div>
    </div>
  );
};

export default AspectRatioContainer;
