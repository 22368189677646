// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2WGoaFWRGQbFyZgQyyFmNb{display:flex;height:100%;align-items:center;flex-direction:column;justify-content:center}.jx0NK5K_5l1Z3s6UVLeCq{height:60%;width:60%;flex-direction:column;justify-content:space-between;display:flex;text-align:center}._2D0g9KNtQ0GRWWaF1mEEHV{font-weight:600;font-size:3.14286rem;line-height:4.28571rem;color:#25436d;text-align:center;margin:0 auto}\n", ""]);
// Exports
exports.locals = {
	"modalBody": "_2WGoaFWRGQbFyZgQyyFmNb",
	"modalBodyWrapper": "jx0NK5K_5l1Z3s6UVLeCq",
	"modalTitle": "_2D0g9KNtQ0GRWWaF1mEEHV"
};
module.exports = exports;
