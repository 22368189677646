import React from "react";
import classnames from "classnames";
import AspectRatioContainer from "./AspectRatioContainer";
import TransitionProvider from "../TransitionProvider/TransitionProvider";

import style from "./BaseLayout.module.scss";

interface BaseProps {
  top: React.ReactNode;
  bottom?: React.ReactNode;
  children: React.ReactNode;
  type?: "primary" | "secondary";
}

const BaseLayoutAnimated: React.FC<BaseProps> = (props: BaseProps) => {
  const { type } = props;
  const classNameType = type ? style[type] : style.primary;

  return (
    <AspectRatioContainer>
      <TransitionProvider>
        <div className={classnames(style.baseLayout, classNameType)}>
          <div className={style.baseLayoutTop}>{props.top}</div>
          <div className={style.baseLayoutContent}>{props.children}</div>
          {props.bottom && <div>{props.bottom}</div>}
        </div>
      </TransitionProvider>
    </AspectRatioContainer>
  );
};

export default BaseLayoutAnimated;
