// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1ccXH1zmQE-d5rYKhC7cSt{position:absolute;width:100%;height:100%;top:0;left:0;pointer-events:none;z-index:1}._2vtvq3wDg79rgpKe7ImCxA{position:absolute;width:100%;height:100%;top:0;right:0;display:flex;flex-direction:column;align-items:flex-end;padding:2.85714rem 2.85714rem 0 0}\n", ""]);
// Exports
exports.locals = {
	"toastsContainer": "_1ccXH1zmQE-d5rYKhC7cSt",
	"toastsWrapper": "_2vtvq3wDg79rgpKe7ImCxA"
};
module.exports = exports;
