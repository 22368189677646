import { action, computed, observable } from "mobx";
import AwardsApiService from "../services/AwardsApiService";
import { Award, UserAward } from "../interfaces/Award";
import { groupBy } from "lodash-es";

interface Error {
  error: string;
  error_description: string;
}

interface userDataByCategoryType {
  [key: string]: UserAward[];
}

export interface CategoryItemsType {
  id: string;
  data?: UserAward;
}

export default class AlbumStore {
  private PER_PAGE = 10;

  constructor(public awardsApi: AwardsApiService) {
    this.getAwards();
  }

  @observable awardsCategory: Award[] | null = null;
  @observable userAwards: UserAward[] | null = null;
  @observable userDataByCategory: userDataByCategoryType | null = null;
  @observable categoryItems: CategoryItemsType[] = [];
  @observable isError = false;
  @observable error: Error | null = null;
  @observable isFinished = false;
  @observable start = 0;
  @observable currentCategory: Award | null = null;

  @action async getAwards(): Promise<void> {
    try {
      this.awardsCategory = await this.awardsApi.getAwards();
    } catch (e) {
      this.isError = true;
    } finally {
      this.isFinished = true;
    }
  }

  @action async getUserAwards(id: string): Promise<void> {
    try {
      this.userAwards = await this.awardsApi.getUserAwards(id);
    } catch (e) {
      this.isError = true;
    } finally {
      this.isFinished = true;
    }
  }

  @action async getUserAwardsDataByCategory(userId: string): Promise<void> {
    await this.getAwards();
    await this.getUserAwards(userId);

    this.userDataByCategory = groupBy(this.userAwards, (item) => item.awardCategory.id);
  }

  @action async getCategoryItems(userId: string, categoryId: string): Promise<void> {
    await this.getUserAwardsDataByCategory(userId);

    this.currentCategory =
      this.awardsCategory?.find((item) => {
        return item.id === categoryId;
      }) || null;

    const userUnblockedItems = this.userDataByCategory?.[categoryId];

    if (!this.currentCategory) {
      return;
    }

    this.categoryItems = this.currentCategory?.awards.map((item) => {
      const unblockedItem = userUnblockedItems?.find((itemUserAward) => {
        return itemUserAward.id === item.id;
      });

      if (unblockedItem) {
        return {
          ...item,
          data: unblockedItem,
        };
      }

      return item;
    });
  }

  @action prev(): void {
    this.start -= this.PER_PAGE;
  }

  @action next(): void {
    this.start += this.PER_PAGE;
  }

  @computed get isPrevPage(): boolean {
    return this.start > 0;
  }

  @computed get isNextPage(): boolean {
    const listLength = this.currentCategory?.awards.length || 0;
    return this.start < listLength - this.PER_PAGE;
  }

  @computed get actualData(): CategoryItemsType[] {
    if (!this.categoryItems) {
      return [];
    }

    return this.categoryItems.slice(this.start, this.start + this.PER_PAGE);
  }

  @action async addBoostPoints(userId: string, amount: number): Promise<void> {
    try {
      await this.awardsApi.addBoostPoints(userId, amount);
    } catch (e) {
      this.isError = true;
    } finally {
      this.isFinished = true;
    }
  }
}
