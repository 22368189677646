import React from "react";
import classnames from "classnames";

import styles from "./Input.module.scss";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  errorLabel?: string | null;
  additionalValue?: string;
}

const Input: React.FC<Props> = (props: Props) => {
  const { error, errorLabel, type, className, additionalValue, ...restProps } = props;

  return (
    <div className={className}>
      <div className={styles.labelWrapper}>
        <span
          className={classnames(styles.label, {
            [styles.errorLabel]: error,
          })}
        >
          {errorLabel}
        </span>
      </div>
      <span className={styles.inputWrapper}>
        <input
          {...restProps}
          type={type || "text"}
          className={classnames(styles.input, {
            [styles.inputError]: error,
          })}
        />
        <span className={styles.additionalValue}>{additionalValue}</span>
      </span>
    </div>
  );
};

export default Input;
