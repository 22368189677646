class Utils {
  static getAge(birthDate: string): string {
    if (!birthDate) {
      return "";
    }
    const today = new Date().getFullYear();
    const birth = new Date(birthDate).getFullYear();
    const diff = today - birth;

    return diff.toString();
  }

  static getBirthdateFromAge(age: string): string {
    const isNumber = Number.isInteger(parseInt(age));

    if (!isNumber) {
      return "";
    }

    const today = new Date().getFullYear();
    const yearOfBirth = today - parseInt(age);
    const birthDate = `${yearOfBirth}-01-01`;
    return birthDate;
  }
}

export default Utils;
