import React, { useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import stores from "../../../stores";

import AlbumsLayout from "./AlbumsLayout";
import Loader from "../../loader/Loader";

import { createPath } from "../../constants/paths";

import styles from "./AlbumsPage.module.scss";

const AlbumsPage: React.FC = observer(() => {
  const history = useHistory();
  const store = useMemo(() => stores.createAlbumStore(), []);
  const { userId } = useParams();
  const language = stores.language.language;

  useEffect(() => {
    store.getUserAwardsDataByCategory(userId);
  }, [store, userId]);

  const renderAlbums = (): React.ReactNode => {
    if (!store.awardsCategory) {
      return <Loader />;
    }

    return store.awardsCategory?.map((item, index) => {
      const unlockedPictures = store.userDataByCategory?.[item.id] ?? [];
      const unlockedPicturesNumber = unlockedPictures.length;
      const allPicturesNumber = item.awards.length;

      return (
        <div
          key={index}
          className={styles.albumItem}
          onClick={() => history.push(createPath.albumsCategory(userId, item.id))}
        >
          <div className={styles.albumItemImgWrapper}>
            <img src={item.thumbnailUrl} alt={item.id} />
          </div>
          <div className={styles.albumItemAmountWrapper}>
            <span className={styles.albumItemAmount}>{`${unlockedPicturesNumber}/${allPicturesNumber}`}</span>
          </div>
          <div className={styles.albumItemName}>{item.translations[language].name}</div>
        </div>
      );
    });
  };

  return (
    <AlbumsLayout onBack={() => history.push(createPath.next(userId))}>
      <div className={styles.albumItemWrapper}>{renderAlbums()}</div>
    </AlbumsLayout>
  );
});

export default AlbumsPage;
