import { useEffect, RefObject } from "react";

const useOutsideClick = (ref: RefObject<HTMLElement>, callback: () => void): void => {
  useEffect(() => {
    let refClicked = false;
    const currentElement = ref.current;

    const handleRefClick = (): void => {
      refClicked = true;
    };

    const handleDocumentClick = (): void => {
      if (!refClicked) {
        callback();
      }
      refClicked = false;
    };

    document.addEventListener("click", handleDocumentClick);
    currentElement?.addEventListener("click", handleRefClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
      currentElement?.removeEventListener("click", handleRefClick);
    };
  }, [ref, callback]);
};

export default useOutsideClick;
