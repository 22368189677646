import React from "react";
import { observer } from "mobx-react";
import { useIntl } from "react-intl";
import Select from "../../../../ui/Select/Select";
import Input from "../../../../ui/Input/Input";
import { UserProfileStateInterface } from "./UserProfileCreationPage";
import Utils from "../../../services/Utils";
import AnimatedButton from "../../../../ui/AnimatedButton/AnimatedButton";

import BaseLayoutAnimated from "../../../../ui/layout/BaseLayoutAnimated";

import styles from "./UserDataPage.module.scss";
import { ValueType, OptionTypeBase } from "react-select";

export interface SelectValueInterface {
  label: string;
  value: string;
}

interface UserDataProps {
  onBtnNext: () => void;
  onBtnPrev: () => void;
  form: UserProfileStateInterface;
  onFormChange: (name: string, value: string) => void;
}

const UserDataPage: React.FC<UserDataProps> = observer((props: UserDataProps) => {
  const { formatMessage: f } = useIntl();

  const selectOptions = [
    { label: f({ id: "page.user.female" }), value: "f" },
    { label: f({ id: "page.user.male" }), value: "m" },
  ];

  const selectValueIndex = selectOptions.findIndex((item) => item.value === props.form.gender);

  const renderTop = (): React.ReactNode => {
    return (
      <div className={styles.userDataTop}>
        <div>
          <AnimatedButton variant="secondary" onClick={props.onBtnPrev}>
            {f({ id: "btn.back" })}
          </AnimatedButton>
        </div>
        <div className={styles.userDataText}>
          <h2 className={styles.userDataHeader}>{f({ id: "page.user.data.title" })}</h2>
          <p className={styles.userDataInfo}>{f({ id: "page.user.data.text" })}</p>
        </div>
      </div>
    );
  };

  const isNext = props.form.name && props.form.gender && props.form.birthDate;

  return (
    <BaseLayoutAnimated top={renderTop()}>
      <div className={styles.userDataPage}>
        <div className={styles.userDataContainer}>
          <Input
            className={styles.userDataPageInput}
            placeholder={f({ id: "page.user.name" })}
            type="text"
            name="name"
            value={props.form.name}
            onChange={(e) => props.onFormChange(e.target.name, e.target.value)}
          />
          <Select
            className={styles.userDataPageSelect}
            options={selectOptions}
            placeholder={f({ id: "page.user.gender" })}
            onChange={(option: ValueType<OptionTypeBase>) => {
              props.onFormChange("gender", (option as SelectValueInterface).value);
            }}
            value={selectOptions[selectValueIndex]}
          />
          <Input
            className={styles.userDataPageInput}
            placeholder={f({ id: "page.user.age" })}
            type="number"
            min={1}
            onChange={(e) => {
              props.onFormChange("birthDate", Utils.getBirthdateFromAge(e.target.value));
            }}
            value={Utils.getAge(props.form.birthDate)}
          />
          <div className={styles.btnWrapper}>
            <AnimatedButton disabled={!isNext} onClick={props.onBtnNext}>
              {f({ id: "btn.next" })}
            </AnimatedButton>
          </div>
        </div>
      </div>
    </BaseLayoutAnimated>
  );
});

export default UserDataPage;
