import React from "react";
import { useIntl } from "react-intl";
import BaseModalLayout from "../../ui/layout/BaseModalLayout";
import LanguageSwitch from "../components/languageSwitch/LanguageSwitch";
import AnimatedButton from "../../ui/AnimatedButton/AnimatedButton";

import style from "./DeletePromptModal.module.scss";

interface DeletePromptModalProps {
  onDelete?: () => void;
  onHide?: () => void;
}

const DeletePromptModal: React.FC<DeletePromptModalProps> = (props: DeletePromptModalProps) => {
  const { onDelete, onHide } = props;
  const { formatMessage: f } = useIntl();

  const renderTop = (): React.ReactNode => {
    return <LanguageSwitch />;
  };

  const onDeleteSubmit = (): void => {
    onHide && onHide();
    onDelete && onDelete();
  };

  return (
    <BaseModalLayout top={renderTop()}>
      <div className={style.modalBody}>
        <h2 className={style.modalTitle}>{f({ id: "modal.delete.title" })}</h2>
        <div className={style.btnWrapper}>
          <AnimatedButton onClick={onHide}>{f({ id: "modal.delete.btn.cancel" })}</AnimatedButton>
          <AnimatedButton variant="danger" onClick={onDeleteSubmit}>
            {f({ id: "modal.delete.btn.ok" })}
          </AnimatedButton>
        </div>
      </div>
    </BaseModalLayout>
  );
};

export default DeletePromptModal;
