import React from "react";
import { useIntl } from "react-intl";

import BaseLayoutAnimated from "../../../../ui/layout/BaseLayoutAnimated";
import Button from "../../../../ui/Button/Button";

import styles from "./AlbumsLayout.module.scss";

interface AlbumsLayoutInterface {
  imageSrc?: string;
  categoryName?: string;
  children: React.ReactNode;
  onBack: () => void;
}

const AlbumsLayout: React.FC<AlbumsLayoutInterface> = (props: AlbumsLayoutInterface) => {
  const { formatMessage: f } = useIntl();

  const renderTop = (): React.ReactNode => {
    return (
      <div className={styles.albumTop}>
        <div>
          <Button variant="secondary" onClick={props.onBack}>
            {f({ id: "btn.back" })}
          </Button>
        </div>
        <h2 className={styles.albumTopTitle}>{f({ id: "album.title" })}</h2>
      </div>
    );
  };

  const renderCategoryItem = (): React.ReactNode => {
    if (!props.imageSrc) {
      return null;
    }

    return (
      <div className={styles.albumCategoryItem}>
        <div className={styles.albumCategoryImageWrapper}>
          <img src={props.imageSrc} />
        </div>
        <span className={styles.albumCategoryItemTitle}>{props.categoryName}</span>
      </div>
    );
  };

  return (
    <BaseLayoutAnimated top={renderTop()} type="secondary">
      <div className={styles.albumContainer}>
        {renderCategoryItem()}
        {props.children}
      </div>
    </BaseLayoutAnimated>
  );
};

export default AlbumsLayout;
