// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2sjYa_bFVI7fyfKzvUi09v{display:inline-block}._2qsoNUYlBJlzizCd-xVqHz{animation:_2qsoNUYlBJlzizCd-xVqHz 0.2s}@keyframes _2qsoNUYlBJlzizCd-xVqHz{from{transform:scale(0.9)}to{transform:scale(1)}}\n", ""]);
// Exports
exports.locals = {
	"animatedComponent": "_2sjYa_bFVI7fyfKzvUi09v",
	"animation": "_2qsoNUYlBJlzizCd-xVqHz"
};
module.exports = exports;
