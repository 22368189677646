import { v4 as uuid } from "uuid";

import { DefaultMessage, DefaultRequest } from "../interfaces/BaseCommunication";

export type onMessageCallback<T> = (data: T) => void;

export type onRequestCallback<T extends DefaultRequest> =
  // This `["response"] extends never ? any : T["response"]` fix problem when SR and/or RR is base type `DefaultRequest`
  //
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (data: T["request"]) => Promise<T["response"] extends never ? any : T["response"]>;

interface Target {
  window: Window;
  origin: string;
}

interface ResponseMessage<T extends DefaultMessage> {
  requestPhase: "response";
  id: string;
  message: T;
}

interface RequestMessage<T extends DefaultMessage> {
  requestPhase: "request";
  id: string;
  message: T;
}

type NormalMessage<T extends DefaultMessage> = T;

type AnyMessage<T extends DefaultMessage = DefaultMessage> = ResponseMessage<T> | RequestMessage<T> | NormalMessage<T>;

interface CrossOriginMessageEvent<T> extends MessageEvent {
  readonly data: T;
}

function isResponseMessage<T extends DefaultMessage>(message: AnyMessage): message is ResponseMessage<T> {
  return (message as ResponseMessage<T>).requestPhase === "response";
}
function isRequestMessage<T extends DefaultMessage>(message: AnyMessage): message is RequestMessage<T> {
  return (message as RequestMessage<T>).requestPhase === "request";
}
function isNormalMessage<T extends DefaultMessage>(message: AnyMessage): message is NormalMessage<T> {
  return !isResponseMessage(message) && !isRequestMessage(message);
}

type MessageResolver = <T extends DefaultMessage>(data: T) => void;
type RequestResolver = <T extends DefaultRequest>(data: RequestMessage<T["request"]>) => Promise<void>;
type ResponseResolver = <T extends DefaultRequest>(data: T["response"]) => void;

// In each of generic types (SM, RM, SR, RR) you can pass:
//
// * "never". - E.g if you don't want to send and receive any request:
//
// CrossOriginConnector<
//   SendMessage1 | SendMessage2,
//   ReceiveMessage1,
//   never,
//   never
// >
//
// * no define (or pass DefaultMessage for SM, RM / DefaultRequest for SR, RR) - If you want to send / receive any:
//
// CrossOriginConnector                   // can send and receive any Message and Request
//
// CrossOriginConnector<
//   DefaultMessage,                      // can send any Message
//   ReceiveMessage1 | ReceiveMessage2,   // can receive specific Message (ReceiveMessage1 | ReceiveMessage2)
// >                                      // can send and receive any Request
//
// CrossOriginConnector<
//   DefaultMessage,                      // can send any Message
//   ReceiveMessage1 | ReceiveMessage2,   // can receive specific Message (ReceiveMessage1 | ReceiveMessage2)
//   DefaultRequest,                      // can send any Request
//   never                                // can't receive any Request
// >
export default class CrossOriginConnector<
  // Union of Send Message [SM] types with can be send via sendMessage() method
  SM extends DefaultMessage = DefaultMessage,
  // Union of Receive Message [RM] types with can be handle via onMessage() method
  RM extends DefaultMessage = DefaultMessage,
  // Union of Send Request [SR] types with can be send via sendRequest() method
  SR extends DefaultRequest = DefaultRequest,
  // Union of Receive Request [RR] types with can be handle via onRequest() method
  RR extends DefaultRequest = DefaultRequest
> {
  private messageResolvers: { [name: string]: MessageResolver } = {};
  private requestResolvers: { [name: string]: RequestResolver } = {};
  private responseResolvers: { [id: string]: ResponseResolver } = {};

  constructor(private target: Target) {
    window.addEventListener("message", this.onMessageListener);
  }

  private onMessageListener = (e: CrossOriginMessageEvent<AnyMessage>): void => {
    const data = e.data;

    if (isRequestMessage(data)) {
      // Handle Request
      const resolver = this.requestResolvers[data.message.type];

      if (resolver) {
        resolver(data);
      }
    } else if (isResponseMessage(data)) {
      // Handle Response
      const id = data.id;
      const resolver = this.responseResolvers[id];

      if (resolver) {
        resolver(data.message.payload);
        delete this.responseResolvers[id];
      }
    } else if (isNormalMessage(data)) {
      // Handle Message
      const resolver = this.messageResolvers[data.type];

      if (resolver) {
        resolver(data);
      }
    }
  };

  public sendMessage(message: SM): void {
    this.target.window.postMessage(message, this.target.origin);
  }

  // prettier-ignore
  public onMessage<
    T extends RM["type"],
    E extends Extract<RM, { type: T }>
  >(name: T, fn: onMessageCallback<E>): void {
    this.messageResolvers[name] = fn as MessageResolver;
  }

  // prettier-ignore
  public sendRequest<
    T extends SR["request"],
    E extends Extract<SR, { request: { type: T["type"] } }>
  >(message: T): Promise<E["response"]> {
    const id = uuid();

    this.target.window.postMessage(
      {
        requestPhase: "request",
        message,
        id,
      },
      this.target.origin
    );

    return new Promise((resolve) => {
      this.responseResolvers[id] = (response: E["response"]) => {
        resolve(response);
      };
    });
  }

  // prettier-ignore
  public async onRequest<
    T extends RR["request"]["type"],
    E extends Extract<RR, { request: { type: T } }>
  >(name: T, fn: onRequestCallback<E>): Promise<void> {
    this.requestResolvers[name] = (async (data: RequestMessage<E["request"]>) => {
      const responseMessage = await fn(data.message);

      this.target.window.postMessage(
        {
          requestPhase: "response",
          id: data.id,
          message: {
            type: `${data.message.type}__response`,
            payload: responseMessage,
          },
        },
        this.target.origin
      );
    }) as RequestResolver;
  }

  public destroy(): void {
    window.removeEventListener("message", this.onMessageListener);
  }
}
