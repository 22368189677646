import AbstractHttpService from "./AbstractHttpService";
import { GameData, GameDataWrapper } from "../interfaces/GameData";

class GameApiService extends AbstractHttpService {
  getGameData(userId: string, gameId: string): Promise<GameData> {
    return this.http
      .get(`api/specialist/v1/patients/${userId}/games/${gameId}/data`)
      .then((response) => this.resolve<GameDataWrapper>(response))
      .then((response) => response.data)
      .catch(this.reject);
  }

  setGameData(userId: string, gameId: string, data: GameData): Promise<GameData> {
    return this.http
      .put(`api/specialist/v1/patients/${userId}/games/${gameId}/data`, {
        body: JSON.stringify({
          data: data,
        }),
      })
      .then((response) => this.resolve<GameData>(response))
      .catch(this.reject);
  }
}

export default GameApiService;
