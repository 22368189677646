import React, { useMemo, useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import UserDataPage from "./UserDataPage";
import UserDeficitPage from "./UserDeficitPage";
import { DeficitConfig } from "./UserDeficitConfig";
import { paths, createPath } from "../../constants/paths";
import AspectRatio from "../../../../ui/layout/AspectRatioContainer";
import Loader from "../../loader/Loader";

import stores from "../../../stores/";

interface StepsInterface {
  [id: string]: React.ReactElement;
}

export interface UserProfileStateInterface {
  name: string;
  gender: string;
  birthDate: string;
  cognitiveDeficits: string[];
}

const UserProfileCreationPage: React.FC = observer(() => {
  const { id } = useParams();
  const history = useHistory();
  const [step, setStep] = useState(1);

  const store = useMemo(() => stores.createUserStore(), []);

  useEffect(() => {
    if (store.isFinished && !store.isError) {
      history.push(paths.userList);
    }
  }, [store.isFinished, store.isError, history]);

  useEffect(() => {
    if (!id) {
      return;
    }
    store.init(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBtnClick = (step: number): void => {
    setStep(step);
  };

  const onFinish = (): void => {
    store.onFinish(id);
  };

  const steps: StepsInterface = {
    1: (
      <UserDataPage
        onBtnNext={() => onBtnClick(step + 1)}
        onBtnPrev={() => history.push(id ? createPath.user(id) : paths.userList)}
        onFormChange={store.onFormChange}
        form={store.form}
      />
    ),
    2: (
      <UserDeficitPage
        title={DeficitConfig[0].title}
        data={DeficitConfig[0].data}
        selectedData={store.form.cognitiveDeficits}
        onCognitiveChange={store.onCognitiveChange}
        onBtnNext={() => onBtnClick(step + 1)}
        onBtnPrev={() => onBtnClick(step - 1)}
      />
    ),
    3: (
      <UserDeficitPage
        title={DeficitConfig[1].title}
        data={DeficitConfig[1].data}
        selectedData={store.form.cognitiveDeficits}
        onCognitiveChange={store.onCognitiveChange}
        onBtnNext={onFinish}
        onBtnPrev={() => onBtnClick(step - 1)}
        loading={store.loading}
      />
    ),
  };

  const component = steps[step];

  if (id && !store.user) {
    return (
      <AspectRatio>
        <Loader />
      </AspectRatio>
    );
  }

  return component;
});

export default UserProfileCreationPage;
