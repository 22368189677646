// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1g09G3GSRLG5dae5Jctq_F{width:100%;height:100%;display:flex;align-items:center;justify-content:center}._39sPmWFdqtAxm8jOnjztM9{width:11.42857rem}\n", ""]);
// Exports
exports.locals = {
	"loaderWrapper": "_1g09G3GSRLG5dae5Jctq_F",
	"loader": "_39sPmWFdqtAxm8jOnjztM9"
};
module.exports = exports;
