// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3DTcu5Mz2YyKrBaW67Dnwf{font-size:1.28571rem;font-weight:bold}._3DTcu5Mz2YyKrBaW67Dnwf._3b-Qow7JerUyYKzhKfiuA8{color:#ec6370}\n", ""]);
// Exports
exports.locals = {
	"label": "_3DTcu5Mz2YyKrBaW67Dnwf",
	"danger": "_3b-Qow7JerUyYKzhKfiuA8"
};
module.exports = exports;
