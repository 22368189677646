import AbstractHttpService from "./AbstractHttpService";
import { ParameterWrapper, Parameter, ParameterGameGlobals } from "../interfaces/Parameter";

class ParameterApiService extends AbstractHttpService {
  getParameter(id: string): Promise<Parameter> {
    return this.http
      .get(`api/specialist/v1/parameters/${id}`)
      .then((response) => this.resolve<ParameterWrapper>(response))
      .then((response) => response.value)
      .catch(this.reject);
  }

  getGameGlobals(): Promise<ParameterGameGlobals> {
    return this.getParameter("game_globals").then((response) => response as ParameterGameGlobals);
  }
}

export default ParameterApiService;
