// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1_v5xXtNve2mXY1CbRiiHw{background:#25436d;color:#fff;border-radius:.71429rem;border:none;text-transform:uppercase;font-weight:800;letter-spacing:0.08rem;cursor:pointer}._1_v5xXtNve2mXY1CbRiiHw:disabled{opacity:0.5}._1_v5xXtNve2mXY1CbRiiHw._3ldM6M6ieEpSdy_4P104ii{width:100%}._1_v5xXtNve2mXY1CbRiiHw._1qAYXs9VKOexwKcAmXSesq{background:#25436d}._1_v5xXtNve2mXY1CbRiiHw._1qAYXs9VKOexwKcAmXSesq:hover{background:#0c2241}._1_v5xXtNve2mXY1CbRiiHw._3l0uH27AbX-Phh3IhcWnXY{background:#ffbc6d}._1_v5xXtNve2mXY1CbRiiHw._3l0uH27AbX-Phh3IhcWnXY:hover{background:#e79029}._1_v5xXtNve2mXY1CbRiiHw.ARB192JrQLR1htUe7sohc{background:#ff486b}._1_v5xXtNve2mXY1CbRiiHw.ARB192JrQLR1htUe7sohc:hover{background:#bf1536}._1_v5xXtNve2mXY1CbRiiHw._2IblbYKiG1QkDUQS0T1Q7O{background:#b3c2d7;color:#788aa4}._1_v5xXtNve2mXY1CbRiiHw._2IblbYKiG1QkDUQS0T1Q7O:hover{background:#b3c2d7}._1_v5xXtNve2mXY1CbRiiHw.dlnG_EJJq4GRbmCP2lgSt{font-size:1.14286rem;padding:1.28571rem}._1_v5xXtNve2mXY1CbRiiHw._2oRjDYzFDv-qGOo4GvZpyw{font-size:1.71429rem;padding:1.35714rem 2.14286rem}\n", ""]);
// Exports
exports.locals = {
	"btn": "_1_v5xXtNve2mXY1CbRiiHw",
	"block": "_3ldM6M6ieEpSdy_4P104ii",
	"primary": "_1qAYXs9VKOexwKcAmXSesq",
	"secondary": "_3l0uH27AbX-Phh3IhcWnXY",
	"danger": "ARB192JrQLR1htUe7sohc",
	"info": "_2IblbYKiG1QkDUQS0T1Q7O",
	"sm": "dlnG_EJJq4GRbmCP2lgSt",
	"md": "_2oRjDYzFDv-qGOo4GvZpyw"
};
module.exports = exports;
