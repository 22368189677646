import React, { useState, useContext, useRef } from "react";
import classnames from "classnames";
import LanguageStoreContext from "../../context/LanguageStoreContext";
import useOutsideClick from "../../hooks/useOutsideClick";

import pl from "../../assets/images/pl.svg";
import de from "../../assets/images/de.svg";
import en from "../../assets/images/en.svg";

import arrowDown from "../../assets/images/arr-down.svg";

import style from "./LanguageSwitch.module.scss";

interface SrcInterface {
  [id: string]: string;
}

interface languagesInterface {
  slug: string;
  name: string;
  image: string;
}

const LanguageSwitch: React.FC = () => {
  const [openStatus, setOpenStatus] = useState(false);
  const languageStore = useContext(LanguageStoreContext);
  const ref = useRef<HTMLDivElement>(null);

  const languageImages: SrcInterface = {
    pl: pl,
    de: de,
    en: en,
  };

  const availableLanguages: languagesInterface[] = [
    {
      slug: "pl",
      name: "PL",
      image: pl,
    },
    {
      slug: "de",
      name: "DE",
      image: de,
    },
    {
      slug: "en",
      name: "EN",
      image: en,
    },
  ];

  useOutsideClick(ref, () => {
    setOpenStatus(false);
  });

  const getSelectedLanguage = (): languagesInterface | undefined => {
    return availableLanguages.find((item) => item.slug === languageStore.language);
  };

  const toggleDropdown = (): void => {
    setOpenStatus((openStatus) => !openStatus);
  };

  const onChangeLanguage = (language: string): void => {
    languageStore.changeLanguage(language);
    toggleDropdown();
  };

  const renderDropdownMenu = (): React.ReactNode => {
    return availableLanguages
      .filter((item) => item.slug !== languageStore.language)
      .map((item, key) => {
        return (
          <div key={key} className={style.languageSwitchMenu} onClick={() => onChangeLanguage(item.slug)}>
            <span className={style.languageIcoNameWrapper}>{item.name}</span>
            <img className={style.menuImgFlag} src={item.image} alt="" />
          </div>
        );
      });
  };

  return (
    <div ref={ref} className={classnames(style.languageContainer)}>
      <div className={classnames(style.languageSwitch)}>
        <div
          className={classnames(style.languageSwitchWrapper, {
            [style.languageSwitchOpened]: openStatus,
          })}
        >
          <div className={style.languageSwitchMenu} onClick={toggleDropdown}>
            <span className={style.languageIcoNameWrapper}>
              {openStatus ? getSelectedLanguage()?.name : <img src={arrowDown} alt="" />}
            </span>
            <img className={style.menuImgFlag} src={languageImages[languageStore.language]} alt="" />
          </div>
          <div
            className={classnames(style.switchMenuDropdown, {
              [style.dropdownOpen]: openStatus,
            })}
          >
            {renderDropdownMenu()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageSwitch;
