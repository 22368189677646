// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2u0wKj9RUXuvPuRJQecXAx{display:flex;width:100%;height:100%;flex-direction:column;padding:3.64286rem 2.5rem 12.42857rem 2.5rem}._12yZyLzs-czBF2VK5E0iqk{flex:1}._2yd-85H436ujkvqjkWQDHQ{background:#f6f7fb;flex:3;border-radius:3.57143rem}\n", ""]);
// Exports
exports.locals = {
	"baseModalLayout": "_2u0wKj9RUXuvPuRJQecXAx",
	"baseModalLayoutTop": "_12yZyLzs-czBF2VK5E0iqk",
	"baseModalLayoutContent": "_2yd-85H436ujkvqjkWQDHQ"
};
module.exports = exports;
