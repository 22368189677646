import { action, computed, observable, toJS } from "mobx";

import { Session } from "../interfaces/Session";
import SessionApiService from "../services/SessionApiService";
import GameApiService from "../services/GameApiService";
import ParameterApiService from "../services/ParameterApiService";
import AwardApiService from "../services/AwardsApiService";
import GameplayApiService from "../services/GameplaysApiService";
import AnalyticsApiService from "../services/AnalyticsApiService";
import GameManager from "../services/GameManager";

import ProgressBarStore from "./ProgressBarStore";
import ToastsStore from "./ToastsStore";
import UserApiService from "../services/UserApiService";

import { ANALYTICS } from "../components/constants/analytics";

interface InitConfig {
  iframeWindow: Window;
  language: string;
  userId: string;
  onEndSession: (session: Session) => void;
  onNextProgram: () => void;
  onCloseGame: () => void;
}

export default class GameStore {
  @observable awardResolve: null | (() => void) = null;
  @observable session: null | Session = null;
  private game: GameManager | null = null;

  constructor(
    public sessionApi: SessionApiService,
    public gameApi: GameApiService,
    public parameterApi: ParameterApiService,
    public awardsApi: AwardApiService,
    public gameplayApi: GameplayApiService,
    public userApi: UserApiService,
    public progressbar: ProgressBarStore,
    public toasts: ToastsStore,
    public analyticsApi: AnalyticsApiService
  ) {}

  @computed get url(): string | undefined {
    // For development:
    //
    // function getDevUrl(url: string): string | undefined {
    //   switch (url) {
    //     case "/engine/quiz/":
    //       return "http://localhost:3101";
    //     case "/engine/unique-element/":
    //       return "http://localhost:3100";
    //   }
    //
    //   return undefined;
    // }
    //
    // return this.session !== null ? getDevUrl(this.session.currentGameplay.game.url) : undefined;
    return this.session !== null ? this.session.currentGameplay.game.url : undefined;
  }

  @action async init(config: InitConfig): Promise<void> {
    this.session = await this.sessionApi.getActiveSession(config.userId);

    const gameId = this.session.currentGameplay.game.id;
    const gameData = toJS(this.session.currentGameplay.game.parameters);

    const [storageData, user, globalData] = await Promise.all([
      await this.gameApi.getGameData(config.userId, gameId),
      await this.userApi.getUser(config.userId),
      await this.parameterApi.getGameGlobals(),
    ]);

    // We know here that this.url can't be undefined
    const url = this.url as string;

    const initData = {
      runtimeData: {
        language: config.language,
        patientDeficits: {
          sideSkipping: user.cognitiveDeficits.includes("side-skipping"),
          armParesis: user.cognitiveDeficits.includes("arm-paresis"),
          psychomotorRetardation: user.cognitiveDeficits.includes("psychomotor-retardation"),
        },
      },
      globalData: globalData,
      gameData: gameData,
      storageData: storageData,
    };

    const origin = new URL(url, window.location.origin).origin; //supports engines exposed under local subpaths

    this.game = new GameManager(
      {
        userId: config.userId,
        iframe: {
          window: config.iframeWindow,
          origin,
        },
        session: this.session,
        initData: initData,
        onEndSession: config.onEndSession,
        onNextProgram: config.onNextProgram,
        onStartAward: this.startAward,
        onEndAward: this.endAward,
        onCloseGame: config.onCloseGame,
      },
      this.sessionApi,
      this.gameApi,
      this.awardsApi,
      this.gameplayApi,
      this.progressbar,
      this.toasts,
      this.analyticsApi
    );

    this.analyticsApi.sendAnalytics({
      type: ANALYTICS.PATIENT,
      action: ANALYTICS.PROGRAM_START,
      patient: config.userId,
      game: gameId,
    });
  }

  private startAward = (awardResolver: () => void): void => {
    this.awardResolve = awardResolver;
  };

  @action endAward = (): void => {
    if (!this.awardResolve) {
      return;
    }

    this.awardResolve();
    this.awardResolve = null;
  };

  @action onCloseGameAnalytics = (userId: string): void => {
    const gameId = (this.session && this.session.currentGameplay.game.id) || "";
    this.analyticsApi.sendAnalytics({
      type: ANALYTICS.PATIENT,
      action: ANALYTICS.PROGRAM_STOP,
      patient: userId,
      game: gameId,
    });
  };

  @computed get isAward(): boolean {
    return this.awardResolve !== null;
  }

  public destroyGame(): void {
    this.game?.destroy();
  }
}
