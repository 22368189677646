import { action, computed, observable } from "mobx";
import { Session } from "../interfaces/Session";
import PaginationStore from "./PaginationStore";
import SessionApiService from "../services/SessionApiService";
import AnalyticsApiService from "../services/AnalyticsApiService";
import { ApiHttpResponseWithPagination } from "../interfaces/AbstractHttpService";
import { ANALYTICS } from "../components/constants/analytics";

export default class SessionStore {
  constructor(
    public sessionApi: SessionApiService,
    userId: string | undefined,
    public analyticsApi: AnalyticsApiService
  ) {
    if (userId) {
      this.userSessions = new PaginationStore<Session>(() => this.nextPage(userId, 1, 20), {
        perPage: 20,
      });
    } else {
      this.userSessions = null;
    }
  }

  @observable session: Session | null = null;
  @observable userSessions: PaginationStore<Session> | null;
  @observable isSessionFinished = false;
  @observable isError = false;
  @observable isFinished = false;
  @observable index = 0;

  @action async getActiveSession(userId: string): Promise<void> {
    try {
      this.session = await this.sessionApi.getActiveSession(userId);
    } catch (e) {
      this.isError = true;
    } finally {
      this.isFinished = true;
    }
  }

  @action async getUserSessions(): Promise<void> {
    this.userSessions && this.userSessions.getNextPage();
  }

  @action async createNewSession(userId: string): Promise<void> {
    try {
      this.session = await this.sessionApi.createNewSession(userId);

      this.analyticsApi.sendAnalytics({
        type: ANALYTICS.PATIENT,
        action: ANALYTICS.SESSION_START,
        patient: userId,
      });
    } catch (e) {
      this.isError = true;
    }
  }

  @action async onStartSession(userId: string): Promise<void> {
    try {
      this.session = await this.sessionApi.getActiveSession(userId);

      this.analyticsApi.sendAnalytics({
        type: ANALYTICS.PATIENT,
        action: ANALYTICS.SESSION_RESUMPTION,
        patient: userId,
      });
    } catch (e) {
      this.createNewSession(userId);
    }
  }

  @action async finishSession(sessionId: string): Promise<void> {
    try {
      await this.sessionApi.finishSession(sessionId);
    } catch (e) {
      this.isError = true;
    }
  }

  @action async finishGameplay(sessionId: string, gameplayId: string): Promise<void> {
    try {
      await this.sessionApi.finishGameplay(sessionId, gameplayId, {
        startLevel: 8,
        endLevel: 15,
        duration: 2,
        effectiveness: 4,
      });
    } catch (e) {
      this.isError = true;
    }
  }

  @action async nextGameplay(): Promise<void> {
    const sessionId = this.session?.id;
    const gameplayId = this.session?.currentGameplay?.id;

    if (sessionId && gameplayId) {
      await this.finishGameplay(sessionId, gameplayId);
      this.startGameplay(sessionId);
    } else if (sessionId) {
      this.startGameplay(sessionId);
    }
  }

  @action async startGameplay(sessionId: string): Promise<void> {
    try {
      if (this.session === null) {
        return;
      }

      this.session = {
        ...this.session,
        currentGameplay: await this.sessionApi.startGameplay(sessionId),
      };
    } catch (e) {
      if (e.error === "NoGamesAvailable") {
        this.finishSession(sessionId);
        this.isSessionFinished = true;
      }
      this.isError = true;
    } finally {
      this.isFinished = true;
    }
  }

  @computed get isPrevPage(): boolean {
    return this.index > 0;
  }

  @computed get isNextPage(): boolean {
    return this.isNextDataLoaded || !this.userSessions?.isFinish;
  }

  @computed get isNextDataLoaded(): boolean {
    if (!this.userSessions) {
      return false;
    }
    return this.index < this.userSessions.data.length - 1;
  }

  @action prev(): void {
    this.index -= 1;
  }

  @action next(): void {
    if (this.isNextDataLoaded) {
      this.index += 1;
    } else {
      this.index += 1;
      this.userSessions?.getNextPage();
    }
  }

  nextPage = (userId: string, page: number, perPage: number): Promise<ApiHttpResponseWithPagination<Session[]>> => {
    return this.sessionApi.getUserSessions(userId, { perPage, page });
  };
}
