import React from "react";
import classnames from "classnames";
import { observer } from "mobx-react";
import useToastsStore from "../../hooks/useToastsStore";
import Toast from "./Toast";

import stylesAspectRatio from "../../../ui/layout/AspectRatioContainer.module.scss";
import styles from "./ToastsContainer.module.scss";

const ToastsContainer: React.FC = observer(() => {
  const toasts = useToastsStore();

  const deleteToast = (id: number): void => {
    toasts.deleteToast(id);
  };

  return (
    <div className={classnames(styles.toastsContainer, stylesAspectRatio.toastsContainer)}>
      <div className={styles.toastsWrapper}>
        {toasts.data.map((toast, index) => {
          return <Toast key={index} toast={toast} onClose={deleteToast} />;
        })}
      </div>
    </div>
  );
});

export default ToastsContainer;
