import React from "react";
import AnimatedComponent, { RunAnimation } from "../AnimatedComponent/AnimatedComponent";
import Button, { ButtonProps } from "../Button/Button";

interface Props extends ButtonProps, React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
}

const AnimatedButton: React.FC<Props> = (props: Props) => {
  const { children, onClick, ...restProps } = props;

  return (
    <AnimatedComponent>
      {(runAnimation: RunAnimation) => (
        <Button {...restProps} onClick={runAnimation(onClick)}>
          {children}
        </Button>
      )}
    </AnimatedComponent>
  );
};

export default AnimatedButton;
