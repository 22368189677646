import { action, observable } from "mobx";
import { Session } from "../interfaces/Session";
import SessionApiService from "../services/SessionApiService";
import AwardApiService from "../services/AwardsApiService";
import AnalyticsApiService from "../services/AnalyticsApiService";
import { ANALYTICS } from "../components/constants/analytics";

export default class NextProgramStore {
  constructor(
    public sessionApi: SessionApiService,
    public awardsApi: AwardApiService,
    public id: string,
    public analyticsApi: AnalyticsApiService
  ) {
    this.getActiveSession(id);
  }

  @observable session: Session | null = null;
  @observable finishedSessionData: Session | null = null;
  @observable isError = false;
  @observable isFinished = false;
  @observable isSessionFinished = false;

  @action async getActiveSession(userId: string): Promise<void> {
    try {
      this.session = await this.sessionApi.getActiveSession(userId);
    } catch (e) {
      this.isError = true;
    } finally {
      this.isFinished = true;
    }
  }

  @action async finishGameplay(sessionId: string, gameplayId: string): Promise<void> {
    try {
      await this.sessionApi.finishGameplay(sessionId, gameplayId, {
        startLevel: 8,
        endLevel: 15,
        duration: 2,
        effectiveness: 4,
      });
    } catch (e) {
      this.isError = true;
    } finally {
      this.isFinished = true;
    }
  }

  @action async finishSession(sessionId: string): Promise<void> {
    try {
      this.finishedSessionData = await this.sessionApi.finishSession(sessionId);
    } catch (e) {
      this.isError = true;
    }
  }

  @action async startGameplay(sessionId: string): Promise<void> {
    try {
      if (this.session === null) {
        return;
      }

      this.session = {
        ...this.session,
        currentGameplay: await this.sessionApi.startGameplay(sessionId),
      };
    } catch (e) {
      if (e.error === "NoGamesAvailable") {
        this.finishSession(sessionId);
      }
      this.isError = true;
    } finally {
      this.isFinished = true;
    }
  }

  @action async nextGameplay(): Promise<void> {
    const sessionId = this.session?.id;
    const gameplayId = this.session?.currentGameplay?.id;

    if (sessionId && gameplayId) {
      await this.finishGameplay(sessionId, gameplayId);
      this.startGameplay(sessionId);
    } else if (sessionId) {
      this.startGameplay(sessionId);
    }
  }

  @action async drawUserAwards(userId: string, awardId: string): Promise<void> {
    try {
      await this.awardsApi.drawUserAwards(userId, awardId);
    } catch (e) {
      this.isError = true;
    } finally {
      this.isFinished = true;
    }
  }

  @action async addBoostPoints(userId: string, amount: number): Promise<void> {
    try {
      await this.awardsApi.addBoostPoints(userId, amount);
    } catch (e) {
      this.isError = true;
    } finally {
      this.isFinished = true;
    }
  }

  @action async cancelSession(userId: string): Promise<void> {
    try {
      await this.analyticsApi.sendAnalytics({
        type: ANALYTICS.PATIENT,
        action: ANALYTICS.SESSION_STOP,
        patient: userId,
      });
    } catch (e) {
      this.isError = true;
    } finally {
      this.isFinished = true;
    }
  }
}
