import React from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import InfoPage from "./InfoPage";
import { createPath } from "../../constants/paths";

const InfoPagePatients: React.FC = () => {
  const { userId } = useParams();
  const history = useHistory();

  return (
    <InfoPage
      text="page.info.text"
      onBtnClick={() => history.push(createPath.next(userId))}
      onBackClick={() => history.push(createPath.user(userId))}
    />
  );
};

export default InfoPagePatients;
