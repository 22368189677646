import { action, observable } from "mobx";

export default class ProgressBarStore {
  @observable progress = 0;
  @observable visible = false;

  @action setProgress(progress: number, silent = false): Promise<void> {
    if (silent) {
      this.progress = progress;
      return Promise.resolve();
    }

    this.visible = true;
    return new Promise((resolve) => {
      setTimeout(() => {
        this.progress = progress;

        setTimeout(() => {
          this.visible = false;
          resolve();
        }, 1200);
      }, 500);
    });
  }
}
