// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./checked.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "._3sToJdSKX43yTugmxH-7_X{cursor:pointer;display:inline-block}.ilWEuW23K98Fe7qFdajBG input[type=\"checkbox\"]{display:none}.ilWEuW23K98Fe7qFdajBG input[type=\"checkbox\"]+span{display:inline-block;position:relative;width:2.14286rem;height:2.14286rem;vertical-align:middle;background:white;border:2px solid #eaeaea;border-radius:.35714rem;cursor:pointer}.ilWEuW23K98Fe7qFdajBG input[type=\"checkbox\"]:checked+span{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%/80%}.ilWEuW23K98Fe7qFdajBG input[type=\"checkbox\"]+span{margin-right:4px}._2tD_c8Cbj85W0QZ1xRdRad{color:#25436d;font-size:1.57143rem;font-weight:600;padding-left:1.71429rem;vertical-align:middle;cursor:pointer}\n", ""]);
// Exports
exports.locals = {
	"checkboxWrapper": "_3sToJdSKX43yTugmxH-7_X",
	"checkbox": "ilWEuW23K98Fe7qFdajBG",
	"checkboxLabel": "_2tD_c8Cbj85W0QZ1xRdRad"
};
module.exports = exports;
