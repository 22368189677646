import { action, observable } from "mobx";
import AwardsApiService from "../services/AwardsApiService";
import { Award, UserAward } from "../interfaces/Award";
import ToastsStore from "./ToastsStore";

export default class AwardsStore {
  constructor(public awardsApi: AwardsApiService, public toasts: ToastsStore) {
    this.getAwards();
  }

  @observable awardsCategory: Award[] | null = null;
  @observable drawedAward: UserAward | null = null;
  @observable isError = false;
  @observable isFinished = false;

  @action async getAwards(): Promise<void> {
    try {
      this.awardsCategory = await this.awardsApi.getAwards();
    } catch (e) {
      this.isError = true;
    } finally {
      this.isFinished = true;
    }
  }

  @action async drawUserAwards(userId: string, awardId: string): Promise<void> {
    try {
      this.drawedAward = await this.awardsApi.drawUserAwards(userId, awardId);
    } catch (e) {
      this.toasts.add({
        title: e.error,
        message: e.error_description,
        type: "error",
      });
      this.isError = true;
    } finally {
      this.isFinished = true;
    }
  }
}
