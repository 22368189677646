import React from "react";
import classnames from "classnames";

import styles from "./Button.module.scss";

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  variant?: "primary" | "secondary" | "danger" | "info";
  size?: "sm" | "md";
  block?: boolean;
}

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const { children, className, variant, block, size, ...restProps } = props;
  const classNameVariant = variant ? styles[variant] : styles.primary;
  const classNameBlock = block === true ? styles.block : null;
  const classNameSize = size ? styles[size] : styles.md;

  return (
    <button
      {...restProps}
      className={classnames(styles.btn, classNameVariant, classNameBlock, classNameSize, className)}
    >
      {children}
    </button>
  );
};

export default Button;
