// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1AsAsLVn6RPsP0UIGRlN_b{padding:0 2.5rem}._2wDYk6guTDO1t89hBbZkvH{display:flex;justify-content:flex-end;padding-top:1.42857rem}._1Z851ZA1xWknkp1JEGnZFU{font-weight:600;font-size:3.14286rem;text-align:center;color:#25436d}._3kG_2XC0_dU5nt1Az7HwmD{background:#f6f7fb;width:32.14286rem;height:32.14286rem;border-radius:3.57143rem;margin:0 auto;display:flex;align-items:center;justify-content:center;flex-direction:column}.oJLTIr6sG4nnRxqjO1H4X{background:#fff;border:2px solid #eaeaea;box-sizing:border-box;box-shadow:10px 10px 35px rgba(116,116,116,0.08);border-radius:10px;display:inline-block;padding:1.42857rem;width:15.42857rem}.oJLTIr6sG4nnRxqjO1H4X img{width:100%}._1u0TwjDQocpYp1bqtO57oo{font-weight:600;font-size:1.57143rem;line-height:2rem;color:#25436d;width:60%;text-align:center;margin-bottom:0}._2JbvgSLv8IeZR90_2JrTy6{vertical-align:middle;cursor:pointer}._1grJEnklf-w9JKN-kJnIzb{vertical-align:middle;width:6.42857rem}._34csYU08Fk-_opQvX2y0FK{font-weight:600;font-size:1.57143rem;line-height:2rem;color:#25436d;padding-left:1.42857rem;width:10.71429rem;display:inline-block;vertical-align:middle}._1mTLfcVZVP0Fyb5FpDpi6m{padding-top:3.14286rem;text-align:center;padding-bottom:3.35714rem}._pKEpobtI9_jEdssQ1V6q{text-align:center}._1AafXzV6t3mPR2nP7knky4{cursor:pointer}\n", ""]);
// Exports
exports.locals = {
	"nextProgramPage": "_1AsAsLVn6RPsP0UIGRlN_b",
	"nextProgramTop": "_2wDYk6guTDO1t89hBbZkvH",
	"mainTitle": "_1Z851ZA1xWknkp1JEGnZFU",
	"nextProgramTileWrapper": "_3kG_2XC0_dU5nt1Az7HwmD",
	"nextProgramTile": "oJLTIr6sG4nnRxqjO1H4X",
	"tileTitle": "_1u0TwjDQocpYp1bqtO57oo",
	"btnAwards": "_2JbvgSLv8IeZR90_2JrTy6",
	"rewardsImg": "_1grJEnklf-w9JKN-kJnIzb",
	"awardsText": "_34csYU08Fk-_opQvX2y0FK",
	"btnWrapper": "_1mTLfcVZVP0Fyb5FpDpi6m",
	"labelWrapper": "_pKEpobtI9_jEdssQ1V6q",
	"labelBtn": "_1AafXzV6t3mPR2nP7knky4"
};
module.exports = exports;
