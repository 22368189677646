import React from "react";
import { useIntl } from "react-intl";
import BaseLayoutAnimated from "../../../../ui/layout/BaseLayoutAnimated";
import LanguageSwitch from "../../languageSwitch/LanguageSwitch";
import AnimatedButton from "../../../../ui/AnimatedButton/AnimatedButton";
import Input from "../../../../ui/Input/Input";

import styles from "./SettingsPage.module.scss";

const SettingsPage: React.FC = () => {
  const { formatMessage: f } = useIntl();

  const renderTop = (): React.ReactNode => {
    return (
      <div className={styles.settingsTop}>
        <div className={styles.settingsTopMain}>
          <div>
            <AnimatedButton variant="secondary">{f({ id: "page.settings.btn.back" })}</AnimatedButton>
          </div>
          <h2 className={styles.settingsTopMainTitle}>{f({ id: "page.settings.title" })}</h2>
          <LanguageSwitch />
        </div>
        <div>
          <h3 className={styles.settingTitle}>{f({ id: "page.settings.subtitle" })}</h3>
        </div>
      </div>
    );
  };

  return (
    <BaseLayoutAnimated top={renderTop()}>
      <form className={styles.settingsContent}>
        <Input type="password" placeholder={f({ id: "page.settings.placeholder" })} />
        <p className={styles.settingsText}>{f({ id: "page.settings.description" })}</p>
        <div className={styles.settingsContentButtonWrapper}>
          <AnimatedButton>{f({ id: "page.settings.btn.submit" })}</AnimatedButton>
        </div>
      </form>
    </BaseLayoutAnimated>
  );
};

export default SettingsPage;
