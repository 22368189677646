import { action, observable } from "mobx";
import { User, CreateUserData } from "../interfaces/User";
import UserApiService from "../services/UserApiService";
import AnalyticsApiService from "../services/AnalyticsApiService";
import ToastsStore from "./ToastsStore";
import { ANALYTICS } from "../components/constants/analytics";

export default class UserStore {
  constructor(public userApi: UserApiService, public toasts: ToastsStore, public analyticsApi: AnalyticsApiService) {}

  @observable user: User | null = null;
  @observable isInitialized = false;
  @observable isError = false;
  @observable isFinished = false;
  @observable loading = false;

  @observable form: CreateUserData = {
    name: "",
    gender: "",
    birthDate: "",
    cognitiveDeficits: [],
  };

  @action onFormChange = (name: string, value: string): void => {
    const key = name as keyof Omit<CreateUserData, "cognitiveDeficits">;
    this.form[key] = value;
  };

  @action onCognitiveChange = (slug: string): void => {
    const isOptionSelected = this.form.cognitiveDeficits.find((item) => item === slug);
    const slelectedArrayOption: string[] = [...this.form.cognitiveDeficits];

    if (isOptionSelected) {
      slelectedArrayOption.splice(
        slelectedArrayOption.findIndex((item) => item === slug),
        1
      );
    } else slelectedArrayOption.push(slug);

    this.form["cognitiveDeficits"] = slelectedArrayOption;
  };

  @action onFinish = (id?: string): Promise<void> => {
    if (id) {
      return this.updateUser(id, this.form);
    }
    return this.addUser(this.form);
  };

  @action async init(id: string): Promise<void> {
    this.getUser(id);
  }

  @action async addUser(form: CreateUserData): Promise<void> {
    this.loading = true;
    this.isError = false;
    try {
      await this.userApi.addUser(form).then((response) => {
        const apiResponse = response as User;

        this.analyticsApi.sendAnalytics({
          type: ANALYTICS.SPECIALIST,
          action: ANALYTICS.PROFILE_ADD,
          patient: apiResponse.id,
        });
      });
    } catch (e) {
      let errorString = "";

      if (e.extras) {
        const keys = Object.keys(e.extras);
        keys.forEach((e) => (errorString += ` ${e},`));
      }

      this.isError = true;
      this.toasts.add({
        title: e.error,
        message: e.error_description + ":" + errorString,
        type: "error",
      });
    } finally {
      this.loading = false;
      this.isFinished = true;
    }
  }

  @action async updateUser(id: string, form: CreateUserData): Promise<void> {
    this.loading = true;
    try {
      await this.userApi.updateUser(id, form).then(() => {
        this.analyticsApi.sendAnalytics({
          type: ANALYTICS.SPECIALIST,
          action: ANALYTICS.PROFILE_EDIT,
          patient: id,
        });
      });
    } catch (e) {
      let errorString = "";

      if (e.extras) {
        const keys = Object.keys(e.extras);
        keys.forEach((e) => (errorString += ` ${e},`));
      }

      this.isError = true;
      this.toasts.add({
        title: e.error,
        message: e.error_description + ":" + errorString,
        type: "error",
      });
    } finally {
      this.loading = false;
      this.isFinished = true;
    }
  }

  @action async deleteUser(id: string): Promise<void> {
    try {
      await this.userApi.deleteUser(id);
    } catch (e) {
      this.isError = true;
    }
  }

  @action async getUser(id: string): Promise<void> {
    try {
      this.user = await this.userApi.getUser(id);
      this.form = this.user;
    } catch (e) {
      this.isError = true;
    }
  }
}
