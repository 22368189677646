// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1MtjviBkBTEFqQCVCbdJoj{display:flex;flex-direction:column;height:100%;padding-top:1.42857rem}._2yCbjZGwaKbHBna6BCk4Ty{display:flex;justify-content:space-between}._3bk5mRp-Nm88U3mCJlFrDG{height:100%;display:flex;justify-content:center;align-items:center;flex-direction:column}._2sII7cTYIFtl2ZDrbu2UWf{font-size:3.14286rem;font-weight:600;color:#25436d}._24vCM9zJHfZRs6P2GramWQ{color:#ff486b;font-size:1.57143rem;padding-top:.92857rem}.cqs33EMAyOWe7rPuSRsSP{display:flex;justify-content:center;align-items:center;height:100%;flex-direction:column}.dcNMPginUwfXtdELQCnD5{display:flex;width:70%;justify-content:space-evenly}._2CEWQgx-DSppf1TyMB9Imy{text-align:center;cursor:pointer;flex:1}.Gh1WfszkbLPfwZPeAg3dm{background:#fff;width:20rem;height:20rem;padding:2.14286rem;display:flex;align-items:center;justify-content:center;border:2px solid #eaeaea;border-radius:.71429rem;box-shadow:.71429rem .71429rem 2.5rem rgba(119,119,119,0.08);margin-bottom:2.14286rem}.Gh1WfszkbLPfwZPeAg3dm img{width:90%}.emGPUgZPdlhOUW1c6m5k8{font-weight:600;font-size:2.14286rem;color:#25436d}._3llWiZs6Lz_2HnpmipC0A0{padding-top:7.14286rem}._2PGMKIfBwARdw6D-4w_xPE{position:relative;padding:.35714rem 3.57143rem;cursor:pointer}._2PGMKIfBwARdw6D-4w_xPE:before{position:absolute;content:\"\";width:2px;height:100%;top:0;left:0;background:rgba(37,67,109,0.2)}._2PGMKIfBwARdw6D-4w_xPE:after{position:absolute;content:\"\";width:2px;height:100%;top:0;right:0;background:rgba(37,67,109,0.2)}._1yDF4ZB5NKNTIDkWxb-EbB{text-align:right;width:100%;padding-right:4.21429rem}._1yDF4ZB5NKNTIDkWxb-EbB img{width:12.35714rem}\n", ""]);
// Exports
exports.locals = {
	"userProfileTop": "_1MtjviBkBTEFqQCVCbdJoj",
	"userProfileTopButtonWrapper": "_2yCbjZGwaKbHBna6BCk4Ty",
	"userDetails": "_3bk5mRp-Nm88U3mCJlFrDG",
	"userDetailsTitle": "_2sII7cTYIFtl2ZDrbu2UWf",
	"userDetailsSession": "_24vCM9zJHfZRs6P2GramWQ",
	"userProfile": "cqs33EMAyOWe7rPuSRsSP",
	"userMenu": "dcNMPginUwfXtdELQCnD5",
	"userMenuBox": "_2CEWQgx-DSppf1TyMB9Imy",
	"userMenuBoxImage": "Gh1WfszkbLPfwZPeAg3dm",
	"userMenuTitle": "emGPUgZPdlhOUW1c6m5k8",
	"userDelete": "_3llWiZs6Lz_2HnpmipC0A0",
	"userDeleteLabel": "_2PGMKIfBwARdw6D-4w_xPE",
	"logoBottom": "_1yDF4ZB5NKNTIDkWxb-EbB"
};
module.exports = exports;
