import React from "react";
import loader from "../../assets/images/loader.gif";

import style from "./Loader.module.scss";

const Loader: React.FC = () => {
  return (
    <div className={style.loaderWrapper}>
      <img src={loader} className={style.loader} alt="" />
    </div>
  );
};

export default Loader;
