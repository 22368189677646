import React from "react";
import classnames from "classnames";

import styles from "./Label.module.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
  type?: "danger";
}

const Button: React.FC<Props> = (props: Props) => {
  const { children, type, className } = props;

  const labelTypeClass = type ? styles[type] : null;

  return <span className={classnames(styles.label, className, labelTypeClass)}>{children}</span>;
};

export default Button;
