// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2iy0qaJsIXsOh-yjHdE-17{display:flex;height:100%;width:100%;align-items:center;justify-content:center;padding-top:4.5rem}._3kCyKR1j1VQp0gW6KAT_Cu{height:100%;width:85%;display:flex;flex-direction:column}._2MJcbBqKyoqQPGKPRnAf2T{display:flex;height:100%;width:100%}._1dB0GQB0WVWask201NVajO{padding-bottom:4.21429rem}._3ENz5PspirnFsRdf6KXxoN{display:inline-block;background:#25436d;color:#fff;padding:.85714rem .28571rem;font-weight:600;font-size:1.78571rem;border-radius:.71429rem;width:3.14286rem;text-align:center}._3ENz5PspirnFsRdf6KXxoN.KLwidR_LchotWb4_x8J83{background:#eaeaea;border-color:#bfbfbf;color:#cfcfcf}._2olz40N3mAHX4yJMs5F5_J{width:5.71429rem}._2olz40N3mAHX4yJMs5F5_J img{width:100%;cursor:pointer}._2Zb4XK-T9iRFoF-6pjDeuS{display:flex;justify-content:center;align-items:center;flex:0 0 34.64286rem;height:36.71429rem}._3iYrBCJ2kOIMwwpFoz5DJd{background:#fff;border:.14286rem solid #eaeaea;box-shadow:.71429rem .71429rem 2.5rem rgba(116,116,116,0.08);border-radius:.71429rem;padding:.92857rem}._3iYrBCJ2kOIMwwpFoz5DJd img{max-width:100%;max-height:100%;border-radius:.35714rem}._3iYrBCJ2kOIMwwpFoz5DJd.YzlEEGRDG_yPVXLDtNEqH{width:34.64286rem;height:36.71429rem;display:flex;justify-content:center;align-items:center}._3iYrBCJ2kOIMwwpFoz5DJd.YzlEEGRDG_yPVXLDtNEqH img{width:70%}.ooFDZ12_0D1QbviB1jwC7{padding-left:5.14286rem}.ooFDZ12_0D1QbviB1jwC7 p{font-size:1.35714rem;line-height:152.5%;color:#646464;white-space:pre-wrap}._2ZmPNIhJs4mhOMEDB6ECxr{color:#25436d;font-weight:600;font-size:2rem;line-height:1.5;word-break:break-all}._2-gsBdufbrPzdQWDyEQ0Z1{opacity:0;pointer-events:none}\n", ""]);
// Exports
exports.locals = {
	"albumWrapper": "_2iy0qaJsIXsOh-yjHdE-17",
	"albumItemWrapper": "_3kCyKR1j1VQp0gW6KAT_Cu",
	"albumItemContent": "_2MJcbBqKyoqQPGKPRnAf2T",
	"albumItemNumberWrapper": "_1dB0GQB0WVWask201NVajO",
	"albumItemNumber": "_3ENz5PspirnFsRdf6KXxoN",
	"albumItemNumberDisabled": "KLwidR_LchotWb4_x8J83",
	"albumArrow": "_2olz40N3mAHX4yJMs5F5_J",
	"albumItemContentImageWrapper": "_2Zb4XK-T9iRFoF-6pjDeuS",
	"albumItemContentImage": "_3iYrBCJ2kOIMwwpFoz5DJd",
	"blocked": "YzlEEGRDG_yPVXLDtNEqH",
	"albumItemContentTextWrapper": "ooFDZ12_0D1QbviB1jwC7",
	"albumItemTitle": "_2ZmPNIhJs4mhOMEDB6ECxr",
	"disabled": "_2-gsBdufbrPzdQWDyEQ0Z1"
};
module.exports = exports;
