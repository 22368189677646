import MODAL_TYPES from "./ModalTypes";

import DeletePromptModal from "./DeletePromptModal";
import BackModal from "./BackModal";

export const config = {
  [MODAL_TYPES.DELETE_PROMPT_MODAL]: DeletePromptModal,
  [MODAL_TYPES.BACK_MODAL]: BackModal,
};

export default config;
