import React from "react";
import Select from "react-select";
import classnames from "classnames";
import { Props as ReactSelectProps } from "react-select";

import "./Select.scss";

interface Props extends ReactSelectProps {
  placeholder?: string;
  className?: string;
}

const SelectComponent: React.FC<Props> = (props: Props) => {
  const { className, placeholder, ...restProps } = props;

  return (
    <Select
      {...restProps}
      className={classnames("react-select-container", className)}
      classNamePrefix="react-select"
      placeholder={placeholder}
    />
  );
};

export default SelectComponent;
