// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._13uGSQXdywNzx08mIz_dtM{display:flex;height:100%;flex-direction:column;padding-top:1.42857rem}.yZHU1i321HB53oQJFdkNQ{display:flex;height:100%;flex-direction:column;align-items:center;justify-content:center}._3vrD1-_uUbm2_RhduarNTZ{margin:.71429rem 0;font-weight:600;font-size:3.14286rem;color:#25436d}._354gjDOzhESarXhXxsiG-G{color:#8e8e8e;font-size:1.28571rem;width:30%;text-align:center;line-height:152.5%}._1C4XCqdwF9fo708_MoSXSv{display:flex;align-items:center;justify-content:center;height:100%}._2h3dMI1DDWNAUoDbZhX9fA{width:40%;text-align:center}._2-K2N2gDFEHHnXio2NG01A{margin-bottom:2.14286rem}._gKi1r1GWvk6pvQGUrazU{margin-bottom:2.14286rem}.klmrOz2gh5zuKLW-DtCmN{padding-top:8.42857rem}\n", ""]);
// Exports
exports.locals = {
	"userDataTop": "_13uGSQXdywNzx08mIz_dtM",
	"userDataText": "yZHU1i321HB53oQJFdkNQ",
	"userDataHeader": "_3vrD1-_uUbm2_RhduarNTZ",
	"userDataInfo": "_354gjDOzhESarXhXxsiG-G",
	"userDataPage": "_1C4XCqdwF9fo708_MoSXSv",
	"userDataContainer": "_2h3dMI1DDWNAUoDbZhX9fA",
	"userDataPageInput": "_2-K2N2gDFEHHnXio2NG01A",
	"userDataPageSelect": "_gKi1r1GWvk6pvQGUrazU",
	"btnWrapper": "klmrOz2gh5zuKLW-DtCmN"
};
module.exports = exports;
