import React, { useState, ReactNode } from "react";
import classnames from "classnames";
import styles from "./AnimatedComponent.module.scss";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Event = any;
type EventHandler = (e?: Event) => void;
export type RunAnimation = (callback?: EventHandler) => EventHandler;

interface Props {
  children: (runAnimation: RunAnimation) => ReactNode;
  className?: string;
}

const AnimatedComponent: React.FC<Props> = (props: Props) => {
  const { children, className } = props;

  const [animation, setAnimation] = useState(false);

  const runAnimation = (callback?: EventHandler): EventHandler => {
    return (e: Event) => {
      setAnimation(true);

      setTimeout(() => {
        setAnimation(false);
        callback && callback(e);
      }, 200);
    };
  };

  return (
    <div
      className={classnames(styles.animatedComponent, className, {
        [styles.animation]: animation,
      })}
    >
      {children(runAnimation)}
    </div>
  );
};

export default AnimatedComponent;
