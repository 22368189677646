// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/toast-success.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/toast-error.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "._30sdxapKyOe23cnIZdey7G{padding:.85714rem .85714rem .85714rem 1.42857rem;margin:.71429rem 0;width:22.85714rem;min-height:6.28571rem;border:2px solid #eaeaea;background:white;border-radius:10px;overflow:hidden;position:relative;margin-bottom:.71429rem;cursor:pointer;pointer-events:visible}._30sdxapKyOe23cnIZdey7G:after{position:absolute;width:.78571rem;height:100%;top:0;left:0;background:#eaeaea;content:\"\"}.EObq2k8_XcjIVvpCp3AIH ._21TiSSPQJGze1HJEPVTenZ{color:#4fd46c}.EObq2k8_XcjIVvpCp3AIH ._21TiSSPQJGze1HJEPVTenZ:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.EObq2k8_XcjIVvpCp3AIH:after{background:#4fd46c}._37bZI8CXE3E6bX8qmECw1X ._21TiSSPQJGze1HJEPVTenZ{color:#ff486b}._37bZI8CXE3E6bX8qmECw1X ._21TiSSPQJGze1HJEPVTenZ:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}._37bZI8CXE3E6bX8qmECw1X:after{background:#ff486b}._21TiSSPQJGze1HJEPVTenZ{font-size:1.21429rem;display:flex;width:100%;justify-content:space-between;margin-bottom:.71429rem;font-weight:bold;padding-left:2.85714rem;position:relative}._21TiSSPQJGze1HJEPVTenZ:before{position:absolute;width:2.14286rem;height:2.14286rem;top:50%;margin-top:-1.07143rem;left:0;content:\"\";background-repeat:no-repeat;background-size:contain}._1Hbyw1IOCco3lSjcCbWG6_{font-size:1.14286rem;padding-top:.35714rem}\n", ""]);
// Exports
exports.locals = {
	"toast": "_30sdxapKyOe23cnIZdey7G",
	"success": "EObq2k8_XcjIVvpCp3AIH",
	"title": "_21TiSSPQJGze1HJEPVTenZ",
	"error": "_37bZI8CXE3E6bX8qmECw1X",
	"message": "_1Hbyw1IOCco3lSjcCbWG6_"
};
module.exports = exports;
