import React from "react";
import classnames from "classnames";

import styles from "./ProgressBar.module.scss";
import { observer } from "mobx-react";

interface ProgressBarProps {
  visible: boolean;
  progress: number; // 0-1
}

const ProgressBar: React.FC<ProgressBarProps> = observer(({ progress, visible }) => {
  return (
    <div
      className={classnames(styles.progressBarWrapper, {
        [styles.visible]: visible,
      })}
    >
      <div className={styles.progressbar}>
        <div className={styles.progress} style={{ width: `${progress * 100}%` }} />
      </div>
    </div>
  );
});

export default ProgressBar;
