interface Paths {
  login: string;
  settings: string;
  user: string;
  addUser: string;
  userList: string;
  albumsItem: string;
  albumsCategory: string;
  albums: string;
  info: string;
  next: string;
  default: string;
}

export const paths = {
  login: "/login",
  settings: "/settings",
  user: "/user/:id",
  addUser: "/add-profile/:id?",
  userList: "/list",
  albumsItem: "/awards/:userId/albums/:categoryId/:id",
  albumsCategory: "/awards/:userId/albums/:categoryId",
  albums: "/awards/:userId/albums",
  info: "/info/:userId",
  infoSpecjalist: "/info-specjalist/:userId",
  next: "/next/:userId",
  game: "/patient/:id/gameplay",
  statistic: "/statistic/:userId/:sessionId?",
  award: "/award/:userId",
  default: "/list",
};

export const createPath = {
  login: (): string => paths.login,
  settings: (): string => paths.settings,
  user: (id: string): string => paths.user.replace(":id", id),
  addUser: (id?: string): string => (id ? paths.addUser.replace(":id?", id) : paths.addUser.replace("/:id?", "")),
  userList: (): string => paths.userList,
  albumsItem: (userId: string, categoryId: string, id: number): string =>
    paths.albumsItem.replace(":categoryId", categoryId).replace(":id", id.toString()).replace(":userId", userId),
  albumsCategory: (userId: string, categoryId: string): string =>
    paths.albumsCategory.replace(":categoryId", categoryId).replace(":userId", userId),
  albums: (userId: string): string => paths.albums.replace(":userId", userId),
  info: (userId: string): string => paths.info.replace(":userId", userId),
  infoSpecjalist: (userId: string): string => paths.infoSpecjalist.replace(":userId", userId),
  next: (userId: string): string => paths.next.replace(":userId", userId),
  award: (userId: string): string => paths.award.replace(":userId", userId),
  statistic: (userId: string, sessionId?: string): string =>
    sessionId
      ? paths.statistic.replace(":userId", userId).replace(":sessionId?", sessionId)
      : paths.statistic.replace(":userId", userId).replace("/:sessionId?", ""),
  default: (): string => paths.default,
};
