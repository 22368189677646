import React, { useMemo, useEffect } from "react";
import classnames from "classnames";
import { format } from "date-fns";
import { observer } from "mobx-react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import BaseLayoutAnimated from "../../../../ui/layout/BaseLayoutAnimated";
import arrLeft from "../../../assets/images/arr-left.svg";
import arrRight from "../../../assets/images/arr-right.svg";
import stores from "../../../stores/";
import { Session } from "../../../interfaces/Session";
import { createPath } from "../../constants/paths";
import AspectRatio from "../../../../ui/layout/AspectRatioContainer";
import Loader from "../../loader/Loader";

import AnimatedButton from "../../../../ui/AnimatedButton/AnimatedButton";

import styles from "./StatisticPage.module.scss";

interface LocationProps {
  state?: Session;
}

interface Props {
  location: LocationProps;
}

const StatisticPage: React.FC<Props> = observer((props: Props) => {
  const { formatMessage: f } = useIntl();
  const { userId } = useParams();
  const history = useHistory();
  const language = stores.language.language;

  const store = useMemo(() => stores.createStatisticStore(userId), [userId]);
  const sessionStore = useMemo(() => stores.createSessionStore(userId), [userId]);

  const sessionFinishedData = props.location.state;
  const sessionId = props.location.state?.id;

  useEffect(() => {
    store.init(userId);
    sessionStore.getUserSessions();
  }, [sessionId, sessionStore, userId, store]);

  const currentSession = sessionId ? sessionFinishedData : sessionStore.userSessions?.data[sessionStore.index];
  const sessionCreatedAt = currentSession?.createdAt ? new Date(currentSession?.createdAt) : null;

  useEffect(() => {
    const currentSessionId = currentSession?.id;

    if (!currentSessionId) {
      return;
    }
    store.getGameplays(currentSessionId);
  }, [sessionStore.index, currentSession?.id, store]);

  useEffect(() => {
    if (sessionStore.isSessionFinished) {
      history.push(createPath.user(userId));
    }
  }, [sessionStore.isSessionFinished, history, userId]);

  const renderTop = (): React.ReactNode => {
    return (
      <div className={styles.statisticTop}>
        {!sessionId && (
          <div className={styles.statisticTopBtn}>
            <AnimatedButton variant="secondary" onClick={() => history.push(createPath.user(userId))}>
              {f({ id: "page.settings.btn.back" })}
            </AnimatedButton>
          </div>
        )}
        <div
          className={classnames(styles.statisticTopTitleWrapper, {
            [styles.statisticTopTileMargin]: !sessionId,
          })}
        >
          <h2 className={styles.statisticTopMainTitle}>{store.user?.name}</h2>
          {store.actualData.length > 0 && (
            <div className={styles.statisticSessionTitle}>
              <div
                className={classnames(styles.sessionArrow, {
                  [styles.disabled]: !sessionStore.isPrevPage,
                  [styles.hidden]: sessionId,
                })}
                onClick={() => sessionStore.prev()}
              >
                <img src={arrLeft} alt="" />
              </div>
              <div>
                <p>{f({ id: "statistic.session.date" })}</p>
                <p>{sessionCreatedAt ? format(sessionCreatedAt, "dd.MM.yyyy") : ""}</p>
              </div>
              <div
                className={classnames(styles.sessionArrow, {
                  [styles.disabled]: !sessionStore.isNextPage,
                  [styles.hidden]: sessionId,
                })}
                onClick={() => sessionStore.next()}
              >
                <img src={arrRight} alt="" />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderBottom = (): React.ReactNode => {
    return (
      <div className={styles.btnBottom}>
        <AnimatedButton onClick={() => history.push(createPath.user(userId))}>
          {f({ id: "statistic.session.finish" })}
        </AnimatedButton>
      </div>
    );
  };

  const getLevel = (start: number, end: number): string => {
    if (start && end) {
      if (start !== end) {
        return `${start} ➞ ${end}`;
      }
      return end.toString();
    }

    return "-";
  };

  const formatDuration = (secondsTotal: number): string => {
    if (!secondsTotal) {
      return "-";
    }

    const minutes = Math.floor(secondsTotal / 60);
    const seconds = secondsTotal % 60;
    return `${minutes}m ${seconds.toString().padStart(2, "0")}s`;
  };

  const renderGameplays = (): React.ReactNode => {
    if (store.actualData.length < 1) {
      return <span className={styles.statisticEmptyText}>{f({ id: "statistic.empty.text" })}</span>;
    }
    return store.actualData?.map((item, index) => {
      return (
        <div className={styles.statisticItem} key={`${index}_${item.id}`}>
          <div className={styles.statisticItemImageWrapper}>
            <div className={styles.statisticItemImage}>
              <img src={item.game.iconUrl} alt="" />
            </div>
          </div>
          <div className={styles.statisticTitle}>{item.game.translations[language].name}</div>
          <div className={styles.statistics}>
            <div className={styles.item}>
              <span className={styles.statisticItemTitle}>{f({ id: "statistic.level" })}</span>
              <span className={styles.statisticItemTitleAdditional}>{getLevel(item.startLevel, item.endLevel)}</span>
            </div>
            <div className={styles.item}>
              <span className={styles.statisticItemTitle}>{f({ id: "statistic.effectiveness" })}</span>
              <span className={styles.statisticItemTitleAdditional}>
                {item.effectiveness ? `${Math.round(item.effectiveness * 100)} %` : "-"}
              </span>
            </div>
            <div className={styles.item}>
              <span className={styles.statisticItemTitle}>{f({ id: "statistic.duration" })}</span>
              <span className={styles.statisticItemTitleAdditional}>{formatDuration(item.duration)}</span>
            </div>
          </div>
        </div>
      );
    });
  };

  if (!store.actualData || !store.user) {
    return (
      <AspectRatio>
        <Loader />
      </AspectRatio>
    );
  }

  return (
    <BaseLayoutAnimated top={renderTop()} bottom={sessionId ? renderBottom() : null} type="secondary">
      <div className={styles.statisticContent}>
        <div
          className={classnames(styles.statisticArrow, {
            [styles.disabled]: !store.isPrevPage,
          })}
          onClick={() => store.prev()}
        >
          <img src={arrLeft} alt="" />
        </div>
        <div className={styles.statisticWrapper}>{renderGameplays()}</div>
        <div
          className={classnames(styles.statisticArrow, {
            [styles.disabled]: !store.isNextPage,
          })}
          onClick={() => store.next()}
        >
          <img src={arrRight} alt="" />
        </div>
      </div>
    </BaseLayoutAnimated>
  );
});

export default StatisticPage;
