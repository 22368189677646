// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._39eGtmlguOWM_BvXVyrCkI{opacity:0;width:100%;height:100%}.CyfzqvVrkKWYxJAWU65YM{opacity:1;transition:opacity 0.5s}\n", ""]);
// Exports
exports.locals = {
	"transitionWrapper": "_39eGtmlguOWM_BvXVyrCkI",
	"visible": "CyfzqvVrkKWYxJAWU65YM"
};
module.exports = exports;
