import AbstractHttpService from "./AbstractHttpService";
import { GamePlay, Answer } from "../interfaces/Session";

class GameplaysApiService extends AbstractHttpService {
  getGameplays(sessionId: string): Promise<GamePlay[]> {
    return this.http
      .get(`api/specialist/v1/sessions/${sessionId}/gameplays`)
      .then((response) => this.resolve<GamePlay[]>(response))
      .catch(this.reject);
  }

  postGameplayAnswer(sessionId: string, gameplayId: string, answer: Answer): Promise<GamePlay[]> {
    return this.http
      .post(`api/specialist/v1/sessions/${sessionId}/gameplays/${gameplayId}/question_answers`, {
        body: JSON.stringify(answer),
      })
      .then((response) => this.resolve<GamePlay[]>(response))
      .catch(this.reject);
  }
}

export default GameplaysApiService;
