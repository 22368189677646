import CrossOriginConnector, { onMessageCallback, onRequestCallback } from "./CrossOriginConnector";

import InitMessagePayload, { StorageData, GameConfig } from "../interfaces/InitMessagePayload";
import ReadyMessage from "../interfaces/ReadyMessage";
import InitMessage from "../interfaces/InitMessage";
import SetStoreMessage from "../interfaces/SetStoreMessage";
import CoinAddRequest from "../interfaces/CoinAddRequest";
import EndGameMessage from "../interfaces/EndGameMessage";
import EndQuestionMessage from "../interfaces/EndQuestionMessage";
import CloseGameMessage from "../interfaces/CloseGameMessage";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SendMessages = InitMessage<any, any, any>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ReceiveMessage = ReadyMessage | SetStoreMessage<any> | EndGameMessage | EndQuestionMessage | CloseGameMessage;
type SendRequests = never;
type ReceiveRequests = CoinAddRequest;

export default class AppWebConnector extends CrossOriginConnector<
  SendMessages,
  ReceiveMessage,
  SendRequests,
  ReceiveRequests
> {
  init<G extends GameConfig, L, S extends StorageData>(data: InitMessagePayload<G, L, S>): void {
    this.sendMessage({
      type: "init",
      payload: data,
    });
  }

  onReady(fn: onMessageCallback<ReadyMessage>): void {
    this.onMessage("ready", fn);
  }

  onSetStore<S extends StorageData>(fn: onMessageCallback<SetStoreMessage<S>>): void {
    this.onMessage("set-store", fn);
  }

  onCoinAdd(fn: onRequestCallback<CoinAddRequest>): void {
    this.onRequest("coin-add", fn);
  }

  onEndGame(fn: onMessageCallback<EndGameMessage>): void {
    this.onMessage("end-game", fn);
  }

  onEndQuestion(fn: onMessageCallback<EndQuestionMessage>): void {
    this.onMessage("end-question", fn);
  }

  onCloseGame(fn: onMessageCallback<CloseGameMessage>): void {
    this.onMessage("close-game", fn);
  }
}
