import appConfig from "appConfig";

import LanguageStore from "./LanguageStore";
import AuthStore from "./AuthStore";
import AuthApiService from "../services/AuthApiService";
import HttpConnector from "../services/HttpConnectorService";
import UserStore from "./UserStore";
import UsersStore from "./UsersStore";
import SessionStore from "./SessionStore";
import SessionApiService from "../services/SessionApiService";
import LicenceApiService from "../services/LicenceApiService";
import UserApiService from "../services/UserApiService";
import AnalyticsApiService from "../services/AnalyticsApiService";
import AwardsStore from "./AwardsStore";
import AlbumStore from "./AlbumStore";
import ModalStore from "./ModalStore";
import ToastsStore from "./ToastsStore";
import ProgressBarStore from "./ProgressBarStore";
import NextProgramStore from "./NextProgramStore";
import GameStore from "./GameStore";
import AwardsApiService from "../services/AwardsApiService";
import GameplayApiService from "../services/GameplaysApiService";
import StatisticStore from "./StatisticStore";
import GameApiService from "../services/GameApiService";
import ParameterApiService from "../services/ParameterApiService";

const http = new HttpConnector({
  prefixUrl: appConfig.API_URL,
});

const authApiService = new AuthApiService(http);
const toastsStore = new ToastsStore();
const progressBarStore = new ProgressBarStore();

export default {
  language: new LanguageStore(),
  auth: new AuthStore(authApiService, new AnalyticsApiService(http)),
  createUserStore: (): UserStore => new UserStore(new UserApiService(http), toastsStore, new AnalyticsApiService(http)),
  createUsersStore: (): UsersStore =>
    new UsersStore(
      new UserApiService(http),
      new LicenceApiService(http),
      new SessionApiService(http),
      new AnalyticsApiService(http)
    ),
  createAwardsStore: (): AwardsStore => new AwardsStore(new AwardsApiService(http), toastsStore),
  createAlbumStore: (): AlbumStore => new AlbumStore(new AwardsApiService(http)),
  createSessionStore: (userId?: string): SessionStore =>
    new SessionStore(new SessionApiService(http), userId, new AnalyticsApiService(http)),
  createNextProgramStore: (id: string): NextProgramStore =>
    new NextProgramStore(new SessionApiService(http), new AwardsApiService(http), id, new AnalyticsApiService(http)),
  createGameStore: (): GameStore =>
    new GameStore(
      new SessionApiService(http),
      new GameApiService(http),
      new ParameterApiService(http),
      new AwardsApiService(http),
      new GameplayApiService(http),
      new UserApiService(http),
      progressBarStore,
      toastsStore,
      new AnalyticsApiService(http)
    ),
  createStatisticStore: (userId: string): StatisticStore =>
    new StatisticStore(new GameplayApiService(http), new UserApiService(http), userId, new AnalyticsApiService(http)),
  modal: new ModalStore(),
  toasts: toastsStore,
  progressBar: progressBarStore,
};
