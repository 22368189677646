import { action, observable } from "mobx";
import { ApiHttpResponseWithPagination } from "../interfaces/AbstractHttpService";

export type NextPageAction = (page: number, perPage: number) => Promise<ApiHttpResponseWithPagination<unknown>>;

interface Config {
  perPage: number;
}

export default class PaginationStore<T> {
  config: Config;
  page = 0;

  @observable isLoading = false;
  @observable isFinish = false;
  @observable isError = false;
  @observable data: T[] = [];

  constructor(public nextPageAction: NextPageAction, config: Partial<Config>) {
    const defaultConfig = {
      perPage: 21,
    };

    this.config = { ...defaultConfig, ...config };
  }

  @action getNextPage(): void {
    if (this.isLoading || this.isFinish) {
      return;
    }

    this.isLoading = true;

    this.nextPageAction(++this.page, this.config.perPage)
      .then((nextData: ApiHttpResponseWithPagination<unknown>) => {
        const nextDataBody = nextData.body as ConcatArray<T>;
        this.data = this.data.concat(nextDataBody);
        this.isFinish = nextDataBody.length < this.config.perPage;
      })
      .catch(() => {
        this.isError === true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
