import React, { useContext, useMemo, useEffect } from "react";
import { useIntl } from "react-intl";
import { format } from "date-fns";
import classnames from "classnames";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import stores from "../../../stores";
import AuthStoreContext from "../../../context/AuthStoreContext";
import BaseLayout from "../../../../ui/layout/BaseLayout";
import AspectRatio from "../../../../ui/layout/AspectRatioContainer";
import Input from "../../../../ui/Input/Input";
import AnimatedButton from "../../../../ui/AnimatedButton/AnimatedButton";
import Button from "../../../../ui/Button/Button";
import logo from "../../../assets/images/aba-stroke.svg";
import up from "../../../assets/images/up.svg";
import down from "../../../assets/images/down.svg";
import user from "../../../assets/images/user.svg";
import AnimatedComponent, { RunAnimation } from "../../../../ui/AnimatedComponent/AnimatedComponent";
import Loader from "../../loader/Loader";
import LanguageSwitch from "../../languageSwitch/LanguageSwitch";
import { createPath } from "../../constants/paths";

import styles from "./UsersListPage.module.scss";

const UsersListPage: React.FC = observer(() => {
  const { formatMessage: f } = useIntl();
  const store = useMemo(() => stores.createUsersStore(), []);
  const history = useHistory();
  const auth = useContext(AuthStoreContext);

  useEffect(() => {
    store.actualUserData();
  }, [store, store.actualData]);

  const renderUsers = (): React.ReactNode => {
    return store.computedUsersWithSession.map((item, key) => {
      return (
        <AnimatedComponent key={key} className={styles.inputListData}>
          {(runAnimation: RunAnimation) => (
            <span onClick={runAnimation(() => history.push(createPath.user(item.id)))}>
              <Input
                value={item.name}
                className={styles.inputListData}
                additionalValue={item.lastSession ? format(new Date(item.lastSession), "dd.MM.yyyy") : "-"}
                readOnly
              />
            </span>
          )}
        </AnimatedComponent>
      );
    });
  };

  const logout = (): void => {
    auth.logout();
  };

  const availableSlots = store.licence?.availableRegistrationSlots ?? 0;

  const renderTop = (): React.ReactNode => {
    return (
      <div className={styles.userListPageTop}>
        <div className={styles.buttonsWrapper}>
          <AnimatedButton variant="secondary" onClick={logout}>
            {f({ id: "page.user.list.btn.logout" })}
          </AnimatedButton>
          <LanguageSwitch />
        </div>
        <div className={styles.titleWrapper}>
          <img src={user} className={styles.userIco} />
          <span>{f({ id: "page.user.list.title" })}</span>
        </div>
        <div>
          {availableSlots <= 0 ? (
            <Button size="sm" variant="info" disabled>
              {f({ id: "page.user.list.btn.limit" })}
            </Button>
          ) : (
            <AnimatedButton size="sm" onClick={() => history.push(createPath.addUser())}>
              + {f({ id: "page.user.list.btn.add" })}
            </AnimatedButton>
          )}
        </div>
      </div>
    );
  };

  const renderContent = (): React.ReactNode => {
    if (store.computedUsersWithSession.length < 1) {
      return (
        <div className={styles.userListEmptyText}>
          <span>{f({ id: "page.profile.empty.text" })}</span>
        </div>
      );
    }
    return (
      <React.Fragment>
        <div
          className={classnames(styles.arrowWrapper, {
            [styles.disabled]: !store.isPrevPage,
          })}
        >
          <img src={up} onClick={() => store.prev()} />
        </div>
        <div className={styles.userListData}>
          <div className={styles.userListHeader}>
            <span>{f({ id: "page.user.list.profile" })}</span>
            <span>{f({ id: "page.user.list.last.session" })}</span>
          </div>
          {renderUsers()}
        </div>
        <div
          className={classnames(styles.arrowWrapper, {
            [styles.disabled]: !store.isNextPage,
          })}
        >
          <img src={down} onClick={() => store.next()} />
        </div>
      </React.Fragment>
    );
  };

  if ((!store.computedUsersWithSession || store.computedUsersWithSession.length < 1) && !store.users.isFinish) {
    return (
      <AspectRatio>
        <Loader />
      </AspectRatio>
    );
  }

  return (
    <BaseLayout top={renderTop()}>
      <div className={styles.userListWrapper}>
        {renderContent()}
        <div className={styles.logoBottom}>
          <img src={logo} alt="" />
        </div>
      </div>
    </BaseLayout>
  );
});

export default UsersListPage;
