// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".nkhZeBMA8AdLxHYP4Dqow{display:flex}.UuGdOEUsS29jfXYeCsuNA{width:80%;text-align:center;font-size:3.28571rem;font-weight:600;color:#25436d}._3EGf1FYx0euy8ge0_rjVTW{display:flex;align-items:center;justify-content:center;height:100%;position:relative}._3tik55ghcPOxlmxI2RiLKf{position:absolute;top:-6.42857rem;right:5rem;background:#fff;border:.14286rem solid #eaeaea;box-shadow:.71429rem .71429rem 2.5rem rgba(116,116,116,0.08);border-radius:.71429rem;text-align:center;width:10.85714rem;z-index:10;display:flex;flex-direction:column}.AYiF3zPs91gk34G5c94BG{padding:.42857rem}.AYiF3zPs91gk34G5c94BG img{max-width:100%;max-height:10.71429rem;object-fit:contain;border-radius:.57143rem}._3ErBykA1NLUzTjJtzqliCt{font-weight:600;font-size:1.28571rem;color:#25436d;display:inline-block;padding-bottom:.71429rem}\n", ""]);
// Exports
exports.locals = {
	"albumTop": "nkhZeBMA8AdLxHYP4Dqow",
	"albumTopTitle": "UuGdOEUsS29jfXYeCsuNA",
	"albumContainer": "_3EGf1FYx0euy8ge0_rjVTW",
	"albumCategoryItem": "_3tik55ghcPOxlmxI2RiLKf",
	"albumCategoryImageWrapper": "AYiF3zPs91gk34G5c94BG",
	"albumCategoryItemTitle": "_3ErBykA1NLUzTjJtzqliCt"
};
module.exports = exports;
