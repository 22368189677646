import ky from "ky";
import HttpConnectorService from "./HttpConnectorService";
import { ApiHttpError, ApiHttpResponseWithPagination } from "../interfaces/AbstractHttpService";
import { PaginationConfig } from "../interfaces/AbstractHttpService";

abstract class AbstractHttpService {
  constructor(public http: HttpConnectorService) {}

  createPaginationQuery(config: PaginationConfig): string {
    const query = new URLSearchParams({
      page: config.page.toString(),
    });

    if (config?.perPage) {
      query.append("per_page", config.perPage.toString());
    }

    return query.toString();
  }

  resolve = async <T>(response: Response): Promise<T> => {
    return response.json();
  };

  resolveWithPaginationHeaders = async <T>(response: Response): Promise<ApiHttpResponseWithPagination<T>> => {
    const lastPage = parseInt(response.headers.get("x-last-page") || "0");
    const currentPage = parseInt(response.headers.get("x-page") || "0");
    const perPage = parseInt(response.headers.get("x-per-page") || "0");
    const totalPages = parseInt(response.headers.get("x-last-page") || "0");

    return {
      body: await response.json(),
      responseHeaders: {
        lastPage,
        currentPage,
        perPage,
        totalPages,
      },
    };
  };

  reject = (error: ky.HTTPError): Promise<never> => {
    // `error.response` will be undefined if server don't response
    // See: https://github.com/sindresorhus/ky/issues/107#issuecomment-471112770
    if (!error.response) {
      if (process.env.NODE_ENV === "development") {
        // eslint-disable-next-line
        console.error(error);
      }

      return Promise.reject({
        error: "Communicate error",
        error_description: "Can`t communicate with server",
      });
    }

    // `error.response` is instance of Response class
    // See: https://github.com/sindresorhus/ky/issues/107#issuecomment-476048453
    return error.response.json().then((err: ApiHttpError) => Promise.reject(err));
  };
}

export default AbstractHttpService;
