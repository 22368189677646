// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dqhxgnBQPTD_1gyr-2SvV{display:flex;width:100%;height:100%;flex-direction:column}.dqhxgnBQPTD_1gyr-2SvV._10nrklx3JIZjRTo7vZjnI3{padding:0 2.5rem}.dqhxgnBQPTD_1gyr-2SvV._1jdIBkUZYdnniKm1RnCVE1{padding:1.42857rem 2.5rem 2.5rem 2.5rem}._2Xlueq4y0HDiHWbrr3iLT4{flex:1}._1w5trS5cF33MK7GDX4PMEJ{background:#f6f7fb}._10nrklx3JIZjRTo7vZjnI3 ._1w5trS5cF33MK7GDX4PMEJ{border-top-left-radius:3.57143rem;border-top-right-radius:3.57143rem;flex:2}._1jdIBkUZYdnniKm1RnCVE1 ._1w5trS5cF33MK7GDX4PMEJ{background:#f6f7fb;flex:5;border-radius:3.57143rem}\n", ""]);
// Exports
exports.locals = {
	"baseLayout": "dqhxgnBQPTD_1gyr-2SvV",
	"primary": "_10nrklx3JIZjRTo7vZjnI3",
	"secondary": "_1jdIBkUZYdnniKm1RnCVE1",
	"baseLayoutTop": "_2Xlueq4y0HDiHWbrr3iLT4",
	"baseLayoutContent": "_1w5trS5cF33MK7GDX4PMEJ"
};
module.exports = exports;
