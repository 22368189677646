import React, { useContext, useMemo, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";

import AspectRatioContainer from "../../../../ui/layout/AspectRatioContainer";
import Award from "../../awards/Award";
import ProgressBar from "../../progressBar/ProgressBar";
import stores from "../../../stores/";
import LanguageStoreContext from "../../../context/LanguageStoreContext";
import { createPath } from "../../constants/paths";
import useProgressBarStore from "../../../hooks/useProgressBarStore";
import { Session } from "../../../interfaces/Session";

import styles from "./Game.module.scss";

const Game: React.FC = observer(() => {
  const { id } = useParams();
  const history = useHistory();
  const languageStore = useContext(LanguageStoreContext);
  const store = useMemo(() => stores.createGameStore(), []);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const progressBar = useProgressBarStore();

  function onEndSession(session: Session): void {
    history.push({
      pathname: createPath.statistic(id, session.id),
      state: session,
    });
  }

  function onNextProgram(): void {
    history.push(createPath.next(id));
  }

  function onCloseGame(): void {
    store.onCloseGameAnalytics(id);
    history.push(createPath.next(id));
  }

  function onCloseAward(): void {
    store.endAward();
  }

  useEffect(() => {
    const isInitialized = sessionStorage.getItem("initializedGame");

    if (isInitialized === "true") {
      history.push(createPath.next(id));
      return;
    } else {
      sessionStorage.setItem("initializedGame", "true");
    }

    if (!iframeRef.current || !iframeRef.current.contentWindow) {
      return;
    }

    store.init({
      iframeWindow: iframeRef.current.contentWindow,
      language: languageStore.language,
      userId: id,
      onEndSession,
      onNextProgram,
      onCloseGame,
    });

    // Need to remove listener for "message" events
    return () => store.destroyGame();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AspectRatioContainer>
      <div className={styles.gamePageWrapper}>
        <div className={styles.game}>
          {/* Before we get game url from API this `store.url` will be undefined  */}
          <iframe src={store.url} ref={iframeRef} allow="autoplay" />
          <ProgressBar visible={progressBar.visible} progress={progressBar.progress} />
        </div>
        {store.isAward && <Award userId={id} onClose={onCloseAward} />}
      </div>
    </AspectRatioContainer>
  );
});

export default Game;
