import { RouteProps } from "react-router-dom";
import LoginPage from "../pages/LoginPage/LoginPage";
import UserProfilePage from "../pages/UserProfilePage/UserProfilePage";
import UserDataCreation from "../pages/UserProfilePage/UserProfileCreationPage";
import UsersListPage from "../pages/UsersListPage/UsersListPage";
import SettingsPage from "../pages/SettingsPage/SettingsPage";
import InfoPagePatient from "../pages/InfoPage/InfoPagePatient";
import InfoPageSpecjalist from "../pages/InfoPage/InfoPageSpecjalist";
import NextProgramPage from "../pages/NextProgramPage/NextProgramPage";
import AlbumsPage from "../pages/AwardsPage/AlbumsPage";
import AlbumsCategoryPage from "../pages/AwardsPage/AlbumsCategoryPage";
import AlbumItemPage from "../pages/AwardsPage/AlbumItemPage";
import Game from "../pages/Game/Game";
import Statistic from "../pages/Statistic/StatisticPage";
import { paths } from "./paths";

export enum SCOPES {
  UNAUTHORIZED = "unauthorized",
  AUTHORIZED = "authorized",
}

export interface RouteInterface extends RouteProps {
  scope?: SCOPES;
}

const routes: RouteInterface[] = [
  {
    path: paths.login,
    component: LoginPage,
    scope: SCOPES.UNAUTHORIZED,
  },
  {
    path: paths.settings,
    component: SettingsPage,
    scope: SCOPES.AUTHORIZED,
  },
  {
    path: paths.user,
    component: UserProfilePage,
    scope: SCOPES.AUTHORIZED,
  },
  {
    path: paths.addUser,
    component: UserDataCreation,
    scope: SCOPES.AUTHORIZED,
  },
  {
    path: paths.userList,
    component: UsersListPage,
    scope: SCOPES.AUTHORIZED,
  },
  {
    path: paths.albumsItem,
    component: AlbumItemPage,
    scope: SCOPES.AUTHORIZED,
  },
  {
    path: paths.albumsCategory,
    component: AlbumsCategoryPage,
    scope: SCOPES.AUTHORIZED,
  },
  {
    path: paths.albums,
    component: AlbumsPage,
    scope: SCOPES.AUTHORIZED,
  },
  {
    path: paths.info,
    component: InfoPagePatient,
    scope: SCOPES.AUTHORIZED,
  },
  {
    path: paths.infoSpecjalist,
    component: InfoPageSpecjalist,
    scope: SCOPES.AUTHORIZED,
  },
  {
    path: paths.next,
    component: NextProgramPage,
    scope: SCOPES.AUTHORIZED,
  },
  {
    path: paths.game,
    component: Game,
    scope: SCOPES.AUTHORIZED,
  },
  {
    path: paths.statistic,
    component: Statistic,
    scope: SCOPES.AUTHORIZED,
  },
];

export default routes;
