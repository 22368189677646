// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2YTCerijcW5bSiqHaKqlKa{display:flex;height:100%;flex-direction:column;padding-top:1.42857rem}._2M6ruXaFZJsKEaNdPcQ4t7{height:100%;display:flex;justify-content:center}._2M6ruXaFZJsKEaNdPcQ4t7 img{width:28.57143rem}._1QZX47ks9i7qHi4gWN6kGJ{text-align:center;padding-top:4.07143rem;width:60%;margin:0 auto}._3IpVi2tZSIv-uR6uVrhgad{font-size:3.14286rem;font-weight:600;color:#25436d}.iW7DHULvdRdrEdW89Q-eh{padding-top:4.71429rem;position:relative}._1tM0OBvAukUjpR03yTWrzN{color:#a3a3a3;font-size:1.14286rem;margin-left:.71429rem}._1k8Ut_guXLd-CVe9E0C6jv{padding-top:2.07143rem;padding-bottom:8.21429rem;display:flex;align-items:center}._2iR4Xq0jdAh7oMuadVQShZ{cursor:pointer;text-decoration:none}\n", ""]);
// Exports
exports.locals = {
	"loginPageTop": "_2YTCerijcW5bSiqHaKqlKa",
	"loginPageLogo": "_2M6ruXaFZJsKEaNdPcQ4t7",
	"loginPageContent": "_1QZX47ks9i7qHi4gWN6kGJ",
	"loginPageTitle": "_3IpVi2tZSIv-uR6uVrhgad",
	"loginPageForm": "iW7DHULvdRdrEdW89Q-eh",
	"loginPageCheckboxText": "_1tM0OBvAukUjpR03yTWrzN",
	"loginPageTerms": "_1k8Ut_guXLd-CVe9E0C6jv",
	"loginPageTermsLink": "_2iR4Xq0jdAh7oMuadVQShZ"
};
module.exports = exports;
