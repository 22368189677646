import React, { useEffect, useState } from "react";
import classnames from "classnames";

import styles from "./TransitionProvider.module.scss";

import "./TransitionProvider.module.scss";

interface transitionProps {
  children: React.ReactNode;
}

const TransitionProvider: React.FC<transitionProps> = (props: transitionProps) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(false);
    setTimeout(() => {
      setVisible(true);
    }, 100);
  }, []);

  return (
    <div
      className={classnames(styles.transitionWrapper, {
        [styles.visible]: visible,
      })}
    >
      {props.children}
    </div>
  );
};

export default TransitionProvider;
