import React from "react";
import AspectRatioContainer from "./AspectRatioContainer";

import style from "./BaseModalLayout.module.scss";

interface BaseProps {
  top: React.ReactNode;
  children: React.ReactNode;
}

const BaseModalLayout: React.FC<BaseProps> = (props: BaseProps) => {
  return (
    <AspectRatioContainer>
      <div className={style.baseModalLayout}>
        <div className={style.baseModalLayoutTop}>{props.top}</div>
        <div className={style.baseModalLayoutContent}>{props.children}</div>
      </div>
    </AspectRatioContainer>
  );
};

export default BaseModalLayout;
