// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1rKB9CLjkOl9OKzIiQd3Q9{padding:0 2.5rem;height:100%;display:flex;flex-direction:column}._2y4xJOXNVB9t-HqHw61Pft{padding-top:1.42857rem}._1hx3_bR91WZsmp9ni5nMJR{display:inline-flex;align-content:center;justify-content:center;flex-direction:column;text-align:center;width:100%;height:100%}.W21rIVSpuS9bQkHIUStiz{text-align:center;padding-bottom:7.14286rem;font-weight:600;font-size:3.14286rem;color:#25436d}\n", ""]);
// Exports
exports.locals = {
	"infoPage": "_1rKB9CLjkOl9OKzIiQd3Q9",
	"infoTop": "_2y4xJOXNVB9t-HqHw61Pft",
	"infoContent": "_1hx3_bR91WZsmp9ni5nMJR",
	"infoTitle": "W21rIVSpuS9bQkHIUStiz"
};
module.exports = exports;
