// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2MibpRlB2Ka0KUOCVtQnh3{border:.14286rem solid #eaeaea;box-shadow:.71429rem .71429rem 2.5rem rgba(116,116,116,0.08);border-radius:.71429rem;height:4.64286rem;width:100%;padding:.71429rem 1.5rem;font-size:2rem;font-weight:500;color:#25436d;background:#fff}.EfoRbu6X-rL3LWunwYl_f{border-color:#ff486b;background:#ffeaea}._3CssyeLA_5maFyuIU_jZWO{text-align:center;padding-bottom:.42857rem}._3Wt0R0Fpkwpd7h2Eh9ZT-4{color:#ec6370;font-size:1.14286rem;font-weight:bold;opacity:0;display:inline-block;height:1.71429rem}._2Zg1w7M1b4BmbjKcrseCfP{opacity:1}.CjfmWrH7cHL0X4lK6ZaeV{position:relative;display:block}._2lu1y5E7AOJ0EtI9lHQ2Uz{position:absolute;right:1.42857rem;top:0;height:100%;display:flex;align-items:center;height:100%;color:#25436d;font-size:1.71429rem}::placeholder{color:#bfbfbf;font-size:1.57143rem}\n", ""]);
// Exports
exports.locals = {
	"input": "_2MibpRlB2Ka0KUOCVtQnh3",
	"inputError": "EfoRbu6X-rL3LWunwYl_f",
	"labelWrapper": "_3CssyeLA_5maFyuIU_jZWO",
	"label": "_3Wt0R0Fpkwpd7h2Eh9ZT-4",
	"errorLabel": "_2Zg1w7M1b4BmbjKcrseCfP",
	"inputWrapper": "CjfmWrH7cHL0X4lK6ZaeV",
	"additionalValue": "_2lu1y5E7AOJ0EtI9lHQ2Uz"
};
module.exports = exports;
