import React, { useContext } from "react";
import { observer } from "mobx-react";
import { IntlProvider } from "react-intl";
import LanguageStoreContext from "../../context/LanguageStoreContext";

import messagesDe from "../../assets/i18n/de.json";
import messagesPl from "../../assets/i18n/pl.json";
import messagesEn from "../../assets/i18n/en.json";

interface Translation {
  [key: string]: string;
}

interface MessageInterface {
  [id: string]: Translation;
}

const messagesData: MessageInterface = { de: messagesDe, pl: messagesPl, en: messagesEn };

interface translationProps {
  children: React.ReactNode;
}

const TranslationProvider: React.FC<translationProps> = observer((props: translationProps) => {
  const languageStore = useContext(LanguageStoreContext);
  const selectedLang = messagesData[languageStore.language];

  return (
    <IntlProvider locale={languageStore.language} messages={selectedLang}>
      {props.children}
    </IntlProvider>
  );
});

export default TranslationProvider;
