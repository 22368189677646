// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UXl5m8qFwcSLUMtP6H6hM{width:100%;height:100%;position:relative}.IWusm8dkpTwvymyVQ_t3E{width:100%;height:100%}.IWusm8dkpTwvymyVQ_t3E iframe{width:100%;height:100%;border:0}\n", ""]);
// Exports
exports.locals = {
	"gamePageWrapper": "UXl5m8qFwcSLUMtP6H6hM",
	"game": "IWusm8dkpTwvymyVQ_t3E"
};
module.exports = exports;
