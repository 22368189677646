// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._31dSw4NTkcG2dPg2QQXNRo{width:100vw;height:100vh;position:absolute;left:0;top:0;display:none;background:white;z-index:99}.orHAvByy50_hzpYAamqB-{display:block}\n", ""]);
// Exports
exports.locals = {
	"modalContainer": "_31dSw4NTkcG2dPg2QQXNRo",
	"modalOpened": "orHAvByy50_hzpYAamqB-"
};
module.exports = exports;
