import { action, computed, observable } from "mobx";
import { GamePlay } from "../interfaces/Session";
import GameplayApiService from "../services/GameplaysApiService";
import UserApiService from "../services/UserApiService";
import { User } from "../interfaces/User";
import { ANALYTICS } from "../components/constants/analytics";
import AnalyticsApiService from "../services/AnalyticsApiService";

export default class StatisticStore {
  private PER_PAGE = 3;

  constructor(
    public GameplayApi: GameplayApiService,
    public UserApi: UserApiService,
    public userId: string,
    public analyticsApi: AnalyticsApiService
  ) {}

  @observable user: User | null = null;
  @observable gameplays: GamePlay[] | null = null;
  @observable isInitialized = false;
  @observable isError = false;
  @observable isFinished = false;
  @observable start = 0;

  @action async init(userId: string): Promise<void> {
    this.getGameplays(userId);
    this.getUser(userId);
    this.analyticsApi.sendAnalytics({
      type: ANALYTICS.SPECIALIST,
      action: ANALYTICS.STATISTIC,
      patient: userId,
    });
  }

  @action async getUser(id: string): Promise<void> {
    try {
      this.user = await this.UserApi.getUser(id);
    } catch (e) {
      this.isError = true;
    }
  }

  @action async getGameplays(id: string): Promise<void> {
    try {
      this.gameplays = await this.GameplayApi.getGameplays(id);
      this.start = 0;
    } catch (e) {
      this.isError = true;
    }
  }

  @action prev(): void {
    this.start -= this.PER_PAGE;
  }

  @action next(): void {
    this.start += this.PER_PAGE;
  }

  @computed get isPrevPage(): boolean {
    return this.start > 0;
  }

  @computed get isNextPage(): boolean {
    if (!this.gameplays) {
      return false;
    }

    return this.start < this.gameplays.length - this.PER_PAGE;
  }

  @computed get actualData(): GamePlay[] {
    if (!this.gameplays) {
      return [];
    }

    return this.gameplays.slice(this.start, this.start + this.PER_PAGE);
  }
}
